import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography, Button, FormControl, Select, MenuItem } from "@material-ui/core";
import moment from "moment";
import envars from "../envars";
import { secondaryMain, chartTheme, floorMapDefault, floorMapDefaultPrimary } from "../Themes";
import api, { handleApiFailureWithDialog } from "../utils/api";
import { DOSER_TYPE, RECEIVER_TYPE } from "../utils/constants";
import useInterval from "../utils/use-interval";
import { withSnackbar } from "./SnackbarManager";
import { withDialog } from "./DialogManager";

import PageLoadingView from "../components/PageLoadingView/PageLoadingView";
import CustomLineChart from "../components/CustomLineChart";

import convertPivotName from '../utils/convert-pivot-name';

// const STROKES_COLOR = ["#F39C12", "#F8C471", "#F1C40F", "#F7DC6F", "#FAD7A0", "#F9E79F"];
const SELECT_COLOR = "#F39C12";
const UNSELECT_COLOR = "#FAD7A0";

const ExperimentRealtimeValveChart = (props) => {
  const { from, to, dosers, avgPres = 0, isRealtime, setIsRealtime, onFloorMapChange } = props;

  const defaultStrokes = dosers.map((d, i) => chartTheme.seconday[i % chartTheme.seconday.length]);
  const dataKeys = dosers.map((r) => `_${r.deviceId}`);
  const legendKeys = dosers.map((r) => r.name);

  const [dataInitLoaded, setDataInitLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [lastTime, setLastTime] = useState(from - 5 * 60 * 1000);

  const [storkes, setStorkes] = useState(defaultStrokes);

  const timeInterval = 5 * 1000; // 10000

  useEffect(() => {
    fetchData();
  }, []);

  useInterval(
    async () => {
      await fetchData();
    },
    timeInterval,
    lastTime
  );

  const fetchData = async () => {
    if (!isRealtime) return false;
    // if (moment().subtract(30, "s") >= moment(to)) {
    //   setIsRealtime(false);
    //   return;
    // }

    let deviceId = dosers.map((r) => r.deviceId);
    let query = `devices=${deviceId}&from=${moment(lastTime).valueOf()}`;
    let apiResult = await api("get", `${envars.telemetryServiceUrl}/valve?${query}`);
    if (apiResult.data.success) {
      let valveResults = apiResult.data.result.valve;
      let pressureIndex = apiResult.data.result.pressure;
      let r134aIndex = apiResult.data.result.r134a;
      if (valveResults && valveResults.length > 0) {
        const lastDataTime = moment(valveResults[valveResults.length - 1].timestamp, "YYYY-MM-DDTHH:mm:ss");
        setLastTime(lastDataTime.valueOf());

        valveResults.sort((a, b) => {
          return moment(a.timestamp, "YYYY-MM-DDTHH:mm:ss").valueOf() - moment(b.timestamp, "YYYY-MM-DDTHH:mm:ss").valueOf();
        });

        valveResults.forEach((data) => {
          data.timestamp = moment(data.timestamp, "YYYY-MM-DDTHH:mm:ss").valueOf();
          for (let key of dataKeys) {
            data[key] = +parseFloat(data[key]).toFixed(2);
          }
        });
        valveResults= convertPivotName(valveResults, dosers);
        let valveData;
        if (!dataInitLoaded) {
          valveData = [...valveResults];
        } else {
          valveData = [...data];
          for(let dataPoint of valveResults){
            let idx = valveData.findIndex(d=>d.timestamp === dataPoint.timestamp);
            if(idx > -1){
              valveData[idx] = dataPoint;
            }else{
              valveData.push(dataPoint);
            }
          }
        }
    
        setData(valveData);

        let deviceFloorMapAreas = [];
        let lastData = valveData[valveData.length - 1];
        for (let device of dosers) {
          let area = {
            deviceId: device.deviceId,
            name: device.name
          };
          let key = `_${device.deviceId}`;
          area.preFillColor = lastData[key] === 1 ? secondaryMain : floorMapDefaultPrimary;
          area.data = {
            timestamp: moment(lastData.timestamp).format("YYYY/MM/DD HH:mm:ss"),
            status: lastData[key] === 1 ? "ON" : "OFF",
          };
          if (pressureIndex && pressureIndex[device.deviceId]) {
            let currentPres = pressureIndex[device.deviceId];
            let pressureDelta = currentPres;
            let state = pressureDelta >= 0 ? "+" : "-";
            area.data.pres = `(${state}) ${+Math.abs(pressureDelta).toFixed(2)}`;
          }
          if (r134aIndex && r134aIndex[device.deviceId]) {
            let currentR134a = r134aIndex[device.deviceId];
            area.data.r134a = +Math.abs(currentR134a).toFixed(2);
          }
          deviceFloorMapAreas.push(area);
        }
        // console.log(deviceFloorMapAreas)
        onFloorMapChange(deviceFloorMapAreas);
      }
      if (!dataInitLoaded) setDataInitLoaded(true);
    } else {
      handleApiFailureWithDialog(props.requestDialog, apiResult);
    }
  };

  const renderChart = () => {
    if (!dataInitLoaded) {
      return (
        <Grid container justify="center" alignItems="center">
          <PageLoadingView />
        </Grid>
      );
    } else {
      return (
        <Fragment>
          <Grid item xs={12}>
            <CustomLineChart
              key="valve-realtime"
              data={data}
              type="linear"
              Xtype="number"
              yAxisLabel={{ value: "Valve", angle: -90, position: "insideLeft" }}
              dataKeys={legendKeys}
              strokes={storkes}
              storkeWidth={1.5}
              activeDot={{ r: 3 }}
              dot={false}
              style={{ width: "100%", height: 150, margin: { top: 0, right: 40, left: 0, bottom: 16 } }}
              isRealtime={isRealtime}
              showBrush={true}
              brushStroke={SELECT_COLOR}
              devices={dosers}
            />
          </Grid>
        </Fragment>
      );
    }
  };

  return (
    <Paper className="paper-with-padding">
      <Grid container spacing={3} justify="space-between">
        <Grid item xs={12}>
          <Typography variant="h6" component="h2">
            Valve Control Device - Realtime
          </Typography>
        </Grid>
      </Grid>
      {renderChart()}
    </Paper>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.system.user,
  };
};

export default connect(mapStateToProps, null)(withDialog(withSnackbar(ExperimentRealtimeValveChart)));
