import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import envars from "../envars";
import { Paper, Typography, Grid, FormControl, Select, MenuItem, InputLabel, makeStyles } from "@material-ui/core";
import api, { handleApiFailureWithDialog } from "../utils/api";

import { DOSER_TYPE, RECEIVER_TYPE, DEVICE_STATUS } from "../utils/constants";
import useInterval from "../utils/use-interval";
import toHKTimeString from "../utils/to-hk-time-string";
import getDeivceStatus from "../utils/get-device-status";

import PageLoadingView from "../components/PageLoadingView/PageLoadingView";
import DeviceStatusIcon from "../components/DeviceStatusIcon";
import DateToggleButtonGroup from "../components/DateToggleButtonGroup";

import { withSnackbar } from "../containers/SnackbarManager";
import { withDialog } from "../containers/DialogManager";
import IAQDataPanel from "../containers/IAQDataPanel";

const IAQPage = (props) => {
  const [loaded, setLoaded] = useState(false);

  const [devices, setDevices] = useState([]);
  const [device, setDevice] = useState(null);
  const [statusChanging, setStatusChanging] = useState(false);
  const [status, setStatus] = useState(DEVICE_STATUS.OFF);

  const [datePeriod, setDatePeriod] = useState(1);
  const [from, setFrom] = useState(moment().subtract(1, "d").valueOf());

  const timeInterval = 10 * 1000; // 10000

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (statusChanging) fetchDeviceData();
  }, [statusChanging]);

  useInterval(
    async () => {
      await fetchDeviceData();
    },
    timeInterval,
    device
  );

  const fetchDeviceData = async () => {
    if (!device) return;
    let apiResult = await api("get", `${envars.deviceServiceUrl}/devices/${device.deviceId}`, null);
    if (apiResult.data.success) {
      let device = apiResult.data.result.device;
      let status = getDeivceStatus(device);
      setStatus(status);
      setStatusChanging(false);
    } else {
      handleApiFailureWithDialog(props.requestDialog, apiResult);
    }
  };

  const fetchData = async () => {
    // setLoaded(false);
    let apiResult = await api("get", `${envars.deviceServiceUrl}/devices?page=1&size=10000`, null);
    if (apiResult.data.success) {
      let devices = apiResult.data.result.devices;
      if (devices) {
        devices = devices.filter((d) => d.type === RECEIVER_TYPE);
        setDevices(devices);
        setDevice(devices[0]);
        setStatusChanging(true);
      } else {
        setDevices(devices);
        setDevice(null);
        setStatusChanging(false);
      }
      setLoaded(true);
    } else {
      handleApiFailureWithDialog(props.requestDialog, apiResult);
    }
  };

  const onDeviceSelectHandler = (e) => {
    let device = devices.find((d) => d.deviceId === e.target.value);
    setDevice(device);
    setStatusChanging(true);
  };

  const onDateSelectHandler = (day) => {
    let from = moment().subtract(day, "d").valueOf();
    setFrom(from);
    setDatePeriod(day);
    // console.log("Date Select Hanlder", moment(from).format("YYYY-MM-DD HH:mm:ss"));
  };

  if (!loaded) return <PageLoadingView />;
  return (
    <div className="paper-with-padding">
      <Typography variant="h4" component="h2">
        IAQ
      </Typography>
      <Paper className="paper-with-padding">
        <Grid container spacing={2}>
          <Grid container spacing={2} style={{ paddingBottom: 16 }}>
            <Grid item xs={12} sm={8}>
              <FormControl fullWidth>
                <InputLabel>Device</InputLabel>
                <Select value={device ? device.deviceId : ""} onChange={onDeviceSelectHandler} displayEmpty>
                  {devices && devices.length >= 0
                    ? devices.map((d) => (
                        <MenuItem
                          key={`device-item-${d.deviceId}`}
                          value={d.deviceId}
                        >{`${d.name} (Device ID ${d.deviceId})  -  Location: ${d.location}`}</MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} justify="space-between">
            <Grid item xs={12} sm={6}>
              <Grid container spacing={1}>
                <Grid item>
                  <Typography variant="body1" color="primary">
                    Device Status:
                  </Typography>
                </Grid>
                <Grid item>
                  {statusChanging ? (
                    <Typography variant="body1" color="textSecondary">
                      Loading...
                    </Typography>
                  ) : (
                    <DeviceStatusIcon status={status} />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <DateToggleButtonGroup value={datePeriod} onDateSelect={onDateSelectHandler} />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <IAQDataPanel device={device} from={from} dayInterval={datePeriod} />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.system.user,
  };
};

export default connect(mapStateToProps, null)(withDialog(withSnackbar(IAQPage)));
