import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography, Button, FormControl, Select, MenuItem } from "@material-ui/core";
import moment from "moment";
import envars from "../envars";
import { chartTheme } from "../Themes";
import api, { handleApiFailureWithDialog } from "../utils/api";
import { DOSER_TYPE, d_TYPE } from "../utils/constants";
// import useInterval from "../utils/use-interval";
import { withSnackbar } from "./SnackbarManager";
import { withDialog } from "./DialogManager";
import {floorMapDefaultPrimary} from '../Themes';

import PageLoadingView from "../components/PageLoadingView/PageLoadingView";
import CustomLineChart from "../components/CustomLineChart";

import convertPivotName from '../utils/convert-pivot-name';


// const STROKES_COLOR = ["#F39C12", "#F8C471", "#F1C40F", "#F7DC6F", "#FAD7A0","#F9E79F"];
const SELECT_COLOR = "#F39C12";
const UNSELECT_COLOR = "#FAD7A0";

const ExperimentHistoryValveChart = (props) => {
  const { from, to, dosers, avgPres = 0, isRealtime, onFloorMapChange } = props;

  const defaultStrokes = dosers.map((d, i) => chartTheme.seconday[i % chartTheme.seconday.length]);
  const dataKeys = dosers.map((r) => `_${r.deviceId}`);
  const legendKeys = dosers.map((r) => r.name);

  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState([]);

  const [storkes, setStorkes] = useState(defaultStrokes);
  const [selectDevice, setSelectDevice] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    let deviceId = dosers.map((r) => r.deviceId);
    let query = `devices=${deviceId}&from=${moment(from).valueOf()}&to=${moment(to).valueOf()}`;
    let apiResult = await api("get", `${envars.telemetryServiceUrl}/valve?${query}`);
    if (apiResult.data.success) {
      let valve = apiResult.data.result.valve;
      let pressureIndex = apiResult.data.result.pressure;
      let r134aIndex = apiResult.data.result.r134a;
      valve.sort((a, b) => {
        return moment(a.timestamp, "YYYY-MM-DDTHH:mm:ss").valueOf() - moment(b.timestamp, "YYYY-MM-DDTHH:mm:ss").valueOf();
      });

      valve.forEach((data) => {
        data.timestamp = moment(data.timestamp, "YYYY-MM-DDTHH:mm:ss").valueOf();
        for (let key of dataKeys) {
          data[key] = +parseFloat(data[key]).toFixed(0);
        }
      });
      let dataWithLegndKey = convertPivotName(valve, dosers);
      setData(dataWithLegndKey);
      let deviceFloorMapAreas = [];
      for (let device of dosers) {
        let area = {
          deviceId: device.deviceId,
          name: device.name
        };
        area.preFillColor = floorMapDefaultPrimary;
        area.data = {};
        if (pressureIndex && pressureIndex[device.deviceId]) {
          let currentPres = pressureIndex[device.deviceId];
          let pressureDelta = currentPres;
          let state = pressureDelta >= 0 ? "+" : "-";
          area.data.pres = `(${state}) ${+Math.abs(pressureDelta).toFixed(2)}`;
        }
        if (r134aIndex && r134aIndex[device.deviceId]) {
          let currentR134a = r134aIndex[device.deviceId];
          area.data.r134a = +Math.abs(currentR134a).toFixed(2);
        }
        deviceFloorMapAreas.push(area);
      }
      // console.log(deviceFloorMapAreas)
      onFloorMapChange(deviceFloorMapAreas);
      setLoaded(true);
    } else {
      handleApiFailureWithDialog(props.requestDialog, apiResult);
    }
  };

  const resetLineChartClick = (e) => {
    setStorkes(defaultStrokes);
    setSelectDevice(null);
  };

  const selectLine = (e) => {
    let value = e.dataKey || e.target.value;
    let selectedDeviceId = dosers.find((d) => d.name === value);
    let selectedLineIdx = dataKeys.findIndex((k) => k === value);
    let newStorkes = [...storkes];
    newStorkes = newStorkes.map((stroke, i) => (i === selectedLineIdx ? SELECT_COLOR : UNSELECT_COLOR));
    setStorkes(newStorkes);
    setSelectDevice(selectedDeviceId);
  };

  const renderChart = () => {
    if (!loaded) {
      return (
        <Grid container justify="center" alignItems="center">
          <PageLoadingView />
        </Grid>
      );
    } else {
      return (
        <Fragment>
          <Grid item xs={12}>
            <CustomLineChart
              key="valve-history"
              data={data}
              type="linear"
              Xtype="number"
              yAxisLabel={{ value: "Valve Release", angle: -90, position: "insideLeft" }}
              dataKeys={legendKeys}
              strokes={storkes}
              storkeWidth={1.5}
              activeDot={{ r: 3 }}
              dot={false}
              style={{ width: "100%", height: 200, margin: { top: 0, right: 40, left: 0, bottom: 16 } }}
              isRealtime={isRealtime}
              // showBrush={true}
              brushStroke={SELECT_COLOR}
              devices={dosers}
            />
          </Grid>
        </Fragment>
      );
    }
  };

  return (
    <Paper className="paper-with-padding">
      <Grid container spacing={3} justify="space-between">
        <Grid item xs={12}>
          <Typography variant="h6" component="h2">
            Valve Control Device - History
          </Typography>
        </Grid>
      </Grid>
      {renderChart()}
    </Paper>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.system.user,
  };
};

export default connect(mapStateToProps, null)(withDialog(withSnackbar(ExperimentHistoryValveChart)));
