import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography, Button, FormControl, Select, MenuItem } from "@material-ui/core";
import moment from "moment";
import envars from "../envars";
import api, { handleApiFailureWithDialog } from "../utils/api";
import { DOSER_TYPE, RECEIVER_TYPE } from "../utils/constants";
// import useInterval from "../utils/use-interval";
import { withSnackbar } from "./SnackbarManager";
import { withDialog } from "./DialogManager";

import PageLoadingView from "../components/PageLoadingView/PageLoadingView";
import CustomLineChart from "../components/CustomLineChart";
import { TrafficOutlined } from "@material-ui/icons";
import { primaryMain, secondaryMain, chartTheme, floorMapDefault } from "../Themes";

import convertPivotName from '../utils/convert-pivot-name';

// const STROKES_COLOR = ["#5B2C6F", "#633974", "#76448A", "#884EA0", "#9B59B6", "#AF7AC5", "#C39BD3", "#D7BDE2"];
const SELECT_COLOR = "#5B2C6F";
const UNSELECT_COLOR = "#EBDEF0";

const ExperimentHistoryDataChart = (props) => {
  const { from, to, receivers, dosers, isRealtime, onFloorMapChange } = props;

  // const devices = receivers.concat(dosers);
  const devices = receivers;
  const defaultStrokes = devices.map((d, i) => chartTheme.primary[i % chartTheme.primary.length]);
  const dataKeys = devices.map((r) => `_${r.deviceId}`);
  const legendKeys = devices.map((r) => ({name:r.name, type: r.type}));

  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState([]);

  const [storkes, setStorkes] = useState(defaultStrokes);
  const [selectDevice, setSelectDevice] = useState(null);

  const [refAreaLeft, setRefAreaLeft] = useState("");
  const [refAreaRight, setRefAreaRight] = useState("");
  const [airFlowRateStart, setAirFlowRateStart] = useState(null);
  const [airFlowRateEnd, setAirFlowRateEnd] = useState(null);
  const [airFlowChangeRate, setAirFlowChangeRate] = useState(null);
  const [airFlowChangeData, setAirFlowChangeData] = useState(null);
  const [airFlowLoading, setAirFlowLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    getAirFlowRate();
  }, [selectDevice, airFlowRateStart, airFlowRateEnd]);

  const fetchData = async () => {
    let reciverIds = receivers.map((r) => r.deviceId);
    let doserIds = dosers.map(d=>d.deviceId);
    let query = `receivers=${reciverIds}&dosers=${doserIds}&from=${moment(from).valueOf()}&to=${moment(to).valueOf()}`;
    let apiResult = await api("get", `${envars.telemetryServiceUrl}/r134a?${query}`);
    if (apiResult.data.success) {
      let ppmData = apiResult.data.result.ppmData;
      let pressureIndex = apiResult.data.result.pressure;
      ppmData.sort((a, b) => {
        return moment(a.timestamp).valueOf() - moment(b.timestamp).valueOf();
      });

      // init empty data time
      let emptyDataTime = {};
      for (let key of dataKeys) {
        emptyDataTime[key] = [];
      }

      for (let i = 0; i < ppmData.length; i++) {
        ppmData[i].timestamp = moment(ppmData[i].timestamp).valueOf();
        for (let key of dataKeys) {
          if (!ppmData[i][key]) {
            emptyDataTime[key].push(i);
          } else {
            const currentValue = +parseFloat(ppmData[i][key]).toFixed(2);
            ppmData[i][key] = currentValue;
            let emptyDataIdx = emptyDataTime[key];
            if(emptyDataIdx.length > 0){
              // let prevEndIdx = emptyDataIdx[0] === 0? 1: emptyDataIdx[0];
              // let prevStartIdx = prevEndIdx-10 < 0? 0: prevEndIdx-10;
              // let lastPpmDataArray =  ppmData.slice(prevStartIdx,prevEndIdx).map(d=>d[key]);
              let lastNonEmptyIdx = emptyDataIdx[0] === 0? 0: emptyDataIdx[0]-1;
              let lastPpmData = ppmData[lastNonEmptyIdx][key];
              let lastEmptyIdx = emptyDataIdx[emptyDataIdx.length-1];
              if(i === (lastEmptyIdx+1)){
                // lastPpmDataArray.push(currentValue);
                // let avgValue = lastPpmDataArray.reduce((a,b) => a + b, 0) / lastPpmDataArray.length;
                let avgValue = (lastPpmData + currentValue) / 2;
                for(let j of emptyDataIdx){
                  ppmData[j][key] = avgValue;
                }
                emptyDataTime[key] = [];
              }
            }
          }
        }
      }

      let dataWithLegndKey = convertPivotName(ppmData, devices);

      setData(dataWithLegndKey);

      const checkAirChange = (value, min) => value / min >= 1.5;
      let deviceFloorMapAreas = [];
      for (let device of devices) {
        let area = {
          deviceId: device.deviceId,
          name: device.name
        };
        let key = `Device${device.deviceId}`;
        let min = Math.min(...ppmData.map((d) => d[key]).filter((d) => d));
        area.data = {};
        let firstChangeTime = ppmData.find((d) => checkAirChange(d[key],min));
        if(firstChangeTime){
          area.data.firstChangeTime = moment(firstChangeTime.timestamp).format("YYYY/MM/DD HH:mm:ss");
        }
        if (pressureIndex && pressureIndex[device.deviceId]) {
          let currentPres = pressureIndex[device.deviceId];
          // let pressureDelta = currentPres - device.avgPres;
          let state = currentPres >= 0 ? "+" : "-";
          area.data.pres = `(${state}) ${+Math.abs(currentPres).toFixed(2)}`;
        }
        area.preFillColor = floorMapDefault;
        deviceFloorMapAreas.push(area);
      }
      onFloorMapChange(deviceFloorMapAreas);
      if (!loaded) setLoaded(true);
    } else {
      handleApiFailureWithDialog(props.requestDialog, apiResult);
    }
  };

  const resetLineChartClick = (e) => {
    setStorkes(defaultStrokes);
    setSelectDevice(null);
    setAirFlowRateStart(null);
    setAirFlowRateEnd(null);
    setAirFlowChangeRate(null);
    setAirFlowChangeData(null);
    setRefAreaRight("");
    setRefAreaLeft("");
  };

  const selectLine = (e) => {
    let value = e.dataKey || e.target.value;
    let selectedDevice = devices.find((d) => d.name === value);
    let selectedLineIdx = legendKeys.findIndex((k) => k.name === value);
    let newStorkes = [...storkes];
    newStorkes = newStorkes.map((stroke, i) => (i === selectedLineIdx ? SELECT_COLOR : UNSELECT_COLOR));
    setStorkes(newStorkes);
    setSelectDevice(selectedDevice);
  };

  const onMouseDown = (e) => {
    if (!e) return;
    setRefAreaLeft(e.activeLabel);
  };

  const onMouseMove = (e) => {
    if (!e) return;
    if (refAreaLeft) setRefAreaRight(e.activeLabel);
  };

  const selectAirFlowChangeRatePeriod = (e) => {
    if (!e) return;
    if (refAreaLeft === refAreaRight || refAreaRight === "") {
      setRefAreaRight("");
      setRefAreaLeft("");
      return;
    }

    let selectedStart = refAreaLeft;
    let selectedEnd = refAreaRight;
    if (refAreaLeft > refAreaRight) [selectedStart, selectedEnd] = [refAreaRight, refAreaLeft];

    setRefAreaRight("");
    setRefAreaLeft("");
    setAirFlowRateStart(moment(selectedStart));
    setAirFlowRateEnd(moment(selectedEnd));
  };

  const getAirFlowRate = async () => {
    // console.log("getAirFlowRate", airFlowRateStart, airFlowRateEnd);
    if (!selectDevice || !airFlowRateStart || !airFlowRateEnd) {
      return;
    }

    setAirFlowLoading(true);
    let airFlowRateApiResult = await api("post", `${envars.mlServiceUrl}/airflow`, {
      deviceId: selectDevice.deviceId,
      type: selectDevice.type,
      from: moment(airFlowRateStart).valueOf(),
      to: moment(airFlowRateEnd).valueOf(),
    });
    if (airFlowRateApiResult && airFlowRateApiResult.data && airFlowRateApiResult.data.ok) {
      let changeRate = airFlowRateApiResult.data.data.result.coef;
      let changeData = airFlowRateApiResult.data.data.result.regression;
      changeRate = +parseFloat(changeRate).toFixed(4);
      if (changeData && changeData.length > 0) {
        changeData.forEach((data) => {
          data.index = +data.index.toFixed(4);
          data.r134a = +data.r134a.toFixed(4);
          data.regression = +data.regression.toFixed(4);
        });
      }
      // console.log(changeData);
      // console.log("AirFlowChangeRate", changeRate);
      setAirFlowChangeRate(changeRate);
      setAirFlowChangeData(changeData);
      setAirFlowLoading(false);
    }
  };

  const renderChart = () => {
    if (!loaded) {
      return (
        <Grid container justify="center" alignItems="center">
          <PageLoadingView />
        </Grid>
      );
    } else {
      return (
        <Fragment>
          <Grid item xs={12}>
            <CustomLineChart
              key="r134a-history"
              data={data}
              type="monotone"
              Xtype="number"
              yAxisLabel={{ value: "R134A(ppm)", angle: -90, position: "insideLeft" }}
              yDomain={["auto", "dataMax + 15"]}
              dataKeys={legendKeys}
              strokes={storkes}
              storkeWidth={1.5}
              activeDot={{ r: 3, onClick: selectLine }}
              dot={false}
              style={{ width: "100%", height: 550, margin: { top: 0, right: 40, left: 0, bottom: 16 } }}
              isRealtime={isRealtime}
              onMouseDown={onMouseDown}
              onMouseMove={onMouseMove}
              onMouseUp={selectAirFlowChangeRatePeriod}
              refAreaLeft={refAreaLeft}
              refAreaRight={refAreaRight}
              showBrush={true}
              brushStroke={SELECT_COLOR}
              devices={devices}
            />
          </Grid>
        </Fragment>
      );
    }
  };

  const renderAirFlowChart = () => {
    if (airFlowLoading) {
      return (
        <Grid container justify="center" alignItems="center">
          <PageLoadingView />
        </Grid>
      );
    } else if (!airFlowLoading && airFlowChangeRate) {
      return (
        <Fragment>
          <Grid item xs={12}>
            <CustomLineChart
              key="r134a-airflow"
              data={airFlowChangeData}
              type="natural"
              yAxisLabel={{ value: "R134A(ppm)", angle: -90, position: "insideLeft" }}
              xDatakey="index"
              dataKeys={["r134a", "regression"]}
              strokes={[secondaryMain, primaryMain]}
              storkeWidth={2}
              activeDot={{ r: 3 }}
              dot={{ customDot: true, r: 2 }}
              style={{ width: "100%", height: 350, margin: { top: 0, right: 40, left: 0, bottom: 16 } }}
              isRealtime={false}
              onMouseDown={() => {}}
              onMouseMove={() => {}}
              onMouseUp={() => {}}
              showBrush={false}
              brushStroke={SELECT_COLOR}
              tickFormatter={(x) => x.toFixed(4)}
              devices={devices}
            />
          </Grid>
        </Fragment>
      );
    }
  };

  return (
    <Paper className="paper-with-padding">
      <Grid container spacing={3} justify="space-between">
        <Grid item xs={12}>
          <Typography variant="h6" component="h2" color="primary">
            Tracer Gas R134A Measurement - History
          </Typography>
        </Grid>
      </Grid>
      {renderChart()}
      <Grid container spacing={1} style={{ paddingTop: 16 }} justify="space-between">
        <Grid item xs={8} sm={6}>
          <Typography variant="h6" component="h2" color="primary">
            Calculate Air Change Rate
          </Typography>
        </Grid>
        <Grid item xs={4} sm={2}>
          <Button fullWidth variant="contained" onClick={resetLineChartClick}>
            Reset
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container justify="flex-start" spacing={2}>
            <Grid item xs={2}>
              <Typography variant="body1" color="primary">
                Device:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <FormControl fullWidth>
                <Select
                  value={selectDevice ? `Device${selectDevice.deviceId}` : ""}
                  onChange={selectLine}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {devices.map((d) => (
                    <MenuItem key={`device-itme-${d.deviceId}`} value={`Device${d.deviceId}`}>{`${d.name} (Device ID ${d.deviceId})`}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" color="primary">
            Timestamp:
            {airFlowRateStart && airFlowRateEnd
              ? `  ${moment(airFlowRateStart).format("YYYY/MM/DD HH:mm:ss")} - ${moment(airFlowRateEnd).format("YYYY/MM/DD HH:mm:ss")}`
              : "  ( Drag the chart to select time ) "}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Grid container spacing={2}>
            <Grid item>
              <Typography variant="button" color="primary">
                AirFlow Change Rate:
              </Typography>
            </Grid>
            <Grid item>
              <Typography color="primary" style={{ fontWeight: "bold" }}>
                {airFlowLoading ? "Loading..." : airFlowChangeRate || null}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              {renderAirFlowChart()}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.system.user,
  };
};

export default connect(mapStateToProps, null)(withDialog(withSnackbar(ExperimentHistoryDataChart)));
