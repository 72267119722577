import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography, Button, Tooltip, FormControl, Select, MenuItem, InputLabel } from "@material-ui/core";
import ImageMapper from "react-image-mapper";
import moment from "moment";
import envars from "../envars";
import { floorMapDefault, floorMapDefaultPrimary } from "../Themes";
import api, { handleApiFailureWithDialog } from "../utils/api";
import { DOSER_TYPE, RECEIVER_TYPE } from "../utils/constants";
import { withSnackbar } from "../containers/SnackbarManager";
import { withDialog } from "../containers/DialogManager";

import ExperimentSettting from "../components/ExperimentSettting";
import PageLoadingView from "../components/PageLoadingView/PageLoadingView";
import toHKTimeString from "../utils/to-hk-time-string";

const DEFAULT_MAP_URLS = [{ label: "Example", value: envars.exampleFloorMapUrl }];
const FLOOR_MAP_URLS = [
  { label: "K11 Musea Floor Plan 1", value: "https://storage.googleapis.com/tracer-gas/floormap/k11-floorplan-1.JPG" },
  { label: "K11 Musea Floor Plan 2", value: "https://storage.googleapis.com/tracer-gas/floormap/k11-floorplan-2.JPG" },
];

const ExperimentCreatePannel = (props) => {
  const {
    experimentId,
    action,
    name: propName = "",
    from: propFrom = moment(),
    to: propTo = null,
    dosers: propDosers = [],
    receivers: propReceivers = [],
    map: propMap = {
      url: envars.exampleFloorMapUrl,
      name: "expeirment-floormap",
      areas: [],
    },
    buttonText = "Save",
    onExperimentReset,
    onExperimentSave,
  } = props;

  const [loaded, setLoaded] = useState(null);
  const [error, setError] = useState(null);
  const [name, setName] = useState(propName);
  const [from, setFrom] = useState(propFrom);
  const [to, setTo] = useState(propTo);
  const [dosers, setDosers] = useState([]);
  const [receivers, setReceivers] = useState([]);

  const [selectDevice, setSelectDevice] = useState({ deviceId: null, name: null });
  const [hoveredArea, setHoveredArea] = useState(null);
  const [map, setMap] = useState(propMap);

  const floorPlanUrls = props.user.community === "annecy" ? DEFAULT_MAP_URLS.concat(FLOOR_MAP_URLS) : DEFAULT_MAP_URLS;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoaded(false);
    // console.log('ExperimentSettingPanel - fetch device data');
    let apiResult = await api("get", `${envars.deviceServiceUrl}/devices?page=1&size=10000`, null);
    if (apiResult.data.success) {
      let selectedDevices = propDosers.concat(propReceivers);
      let devices = apiResult.data.result.devices;
      if (devices) {
        devices = devices.map((d) => {
          let selectedDeivce = selectedDevices.find((sd) => sd.deviceId === d.deviceId);
          let deviceId = d.deviceId;
          let data = {
            ...d,
            ...selectedDeivce,
            deviceId,
            select: selectedDevices.find((s) => s.deviceId === deviceId) ? true : false,
          };
          return data;
        });
        let dosers, receivers;
        dosers = devices.filter((d) => d.type === DOSER_TYPE);
        receivers = devices.filter((d) => d.type === RECEIVER_TYPE);
        setDosers(dosers);
        setReceivers(receivers);
      } else {
        setDosers([]);
        setReceivers([]);
      }

      setError(null);
      setLoaded(true);
    } else {
      handleApiFailureWithDialog(props.requestDialog, apiResult);
    }
  };

  const handleDoserChange = (props) => (event) => {
    let newDosers = [...dosers];
    let change = props === "select" || props === "presetValve" ? event.target.checked : event.target.value;
    newDosers[+event.target.name][props] = change;
    setDosers(newDosers);
  };

  const handleReceiverChange = (props) => (event) => {
    let newReceivers = [...receivers];
    let change = props === "select" ? event.target.checked : event.target.value;
    newReceivers[+event.target.name][props] = change;
    setReceivers(newReceivers);
  };

  const validExperiment = (name, dosers, receivers, from, to) => {
    // console.log(receivers.length);
    if (!name) {
      setError("Please enter the experiment name");
      return false;
    }
    if (dosers.length <= 0) {
      setError("Select at least ONE value control device.");
      return false;
    }
    if (dosers.find((d) => d.presetValve && (!d.valveStart || !d.valveEnd))) {
      setError("Please set the valve preset start time and endtime or uncheck the preset valve setting");
      return false;
    }

    if (receivers.length <= 0) {
      setError("Select at least ONE measurement device.");
      return false;
    }

    if (receivers.find((d) => d.needAvgPressure && !d.avgPres)) {
      setError("Please calculate the average pressure before experiment start");
      return false;
    }

    if (!from) {
      setError("Select the experiment start time");
      return false;
    }
    if (!to) {
      setError("Select the experiment end time");
      return false;
    }

    if (from > to) {
      return "The experiment start time can not be later than end time.";
    }

    setError(null);
    return true;
  };

  const handleExperimentSave = () => {
    let selectDosers = dosers
      .filter((d) => d.select)
      .map((d) => ({
        deviceId: d.deviceId,
        name: d.name,
        location: d.location,
        offset: d.offset,
        avgPres: d.avgPres,
        presetValve: d.presetValve ? d.presetValve : 0,
        valveStart: d.valveStart ? moment(d.valveStart).valueOf() : null,
        valveEnd: d.valveEnd ? moment(d.valveEnd).valueOf() : null,
      }));
    let selectReceivers = receivers
      .filter((d) => d.select)
      .map((d) => ({
        deviceId: d.deviceId,
        name: d.name,
        location: d.location,
        avgPres: d.avgPres,
        offset: d.offset
      }));
    let filterMap = { ...map };
    filterMap.areas.forEach((area) => {
      area.preFillColor = area.type === DOSER_TYPE ? floorMapDefaultPrimary : floorMapDefault;
      delete area.data;
    });

    let valid = validExperiment(name, selectDosers, selectReceivers, from, to);
    if (!valid) return false;

    let data = {
      action,
      name,
      from: moment(from).valueOf(),
      to: moment(to).valueOf(),
      dosers: selectDosers,
      receivers: selectReceivers,
      map,
    };
    // console.log(data);
    onExperimentSave(data);
  };

  const handleCalculateAvgPres = async () => {
    let selectReceivers = receivers.filter((d) => d.select);
    let selectDosers = dosers.filter(d=>d.select);
    if (selectReceivers.length <= 0 || selectDosers.length <= 0) {
      setError("Select at least ONE measurement device.");
      return false;
    }
    setLoaded(false);
    setError(null);
    // let selectDevice = selectReceivers.concat(selectDosers);
    // let deviceId = selectDevice.map((r) => r.deviceId);
    let query = `receivers=${selectReceivers.map((r) => r.deviceId)}&dosers=${selectDosers.map((r) => r.deviceId)}&calculationTime=${moment().valueOf()}`;
    let apiResult = await api("get", `${envars.telemetryServiceUrl}/avgPres?${query}`, null);
    if (apiResult.data.success) {
      let avgPres = apiResult.data.result.avgPres;
      let offset = apiResult.data.result.offset;
      avgPres = +parseFloat(avgPres).toFixed(2);
      // console.log(avgPres);
      let updateReceivers = [...receivers];
      let updateDosers = [...dosers];
      for (let device of selectReceivers) {
        let idx = updateReceivers.findIndex((d) => d.deviceId === device.deviceId);
        if (idx >= 0) {
          updateReceivers[idx].avgPres = avgPres;
          updateReceivers[idx].offset = offset[device.deviceId];
        }
      }
      for (let device of selectDosers) {
        let idx = updateDosers.findIndex((d) => d.deviceId === device.deviceId);
        if (idx >= 0) {
          updateDosers[idx].avgPres = avgPres;
          updateDosers[idx].offset = offset[device.deviceId];
        }
      }

      setReceivers(updateReceivers);
      setDosers(updateDosers);
      setLoaded(true);
    } else {
      setLoaded(true);
      handleApiFailureWithDialog(props.requestDialog, apiResult);
    }
  };

  const handleExperimentReset = async () => {
    // setLoaded(false);
    // if(onExperimentReset) await onExperimentReset();
    setName(propName);
    setFrom(propFrom);
    setTo(propTo);
    setMap(propMap);
    setSelectDevice(null);
    fetchData();
  };

  // Floop Map controller
  const onFloorMapDeviceClick = (area) => {
    // console.log(area);
  };

  // const onFloorMapDeviceHover = (area, evt) =>{
  // }

  const onFloorMapImageClick = (e) => {
    const w = 26;
    const h = 16;
    const x = e.nativeEvent.layerX;
    const y = e.nativeEvent.layerY;
    // let coords = [x, y, 15];
    let coords = [x - w, y - h, x + w, y + h];
    if (selectDevice && selectDevice.deviceId) {
      let updateMap = { ...map };
      let idx = updateMap.areas.findIndex((area) => area.deviceId === selectDevice.deviceId);
      let color = selectDevice.type === DOSER_TYPE ? floorMapDefaultPrimary : floorMapDefault;
      if (idx >= 0) {
        updateMap.areas[idx].shape = "rect";
        updateMap.areas[idx].coords = coords;
        updateMap.areas[idx].preFillColor = color;
      } else {
        // updateMap.areas.push({ name: selectDevice.name, deviceId: selectDevice.deviceId, shape: "circle", coords, preFillColor: "grey" });
        updateMap.areas.push({ type: selectDevice.type, deviceId: selectDevice.deviceId, shape: "rect", coords, preFillColor: color });
      }
      setMap(updateMap);
    }
  };

  const handleSelectFloorMapDevice = (device) => (e) => {
    if (selectDevice && device.deviceId === selectDevice.deviceId) {
      setSelectDevice({ deviceId: null, name: null });
    } else {
      setSelectDevice(device);
    }
  };

  const handleRemoveFloorMapDevice = (device) => (e) => {
    // console.log(deviceId);
    let updateMap = { ...map };
    let idx = updateMap.areas.findIndex((area) => area.deviceId === device.deviceId);
    if (idx > -1) {
      updateMap.areas.splice(idx, 1);
      setMap(updateMap);
    }
  };

  const renderFloorMapHoverData = () => {
    if (!hoveredArea.data) return;
    // {hoveredArea.data ? <Typography color="textSecondary">R134A value: {hoveredArea.data}</Typography> : null}
    const data = hoveredArea.data;
    return (
      <Fragment>
        {data.timestamp ? <Typography color="textSecondary">Time: {data.timestamp}</Typography> : null}
        {data.status ? <Typography color="textSecondary">Status: {data.status}</Typography> : null}
        {data.r134a ? <Typography color="textSecondary">R134A(ppm): {data.r134a}</Typography> : null}
        {data.pres ? <Typography color="textSecondary">Last Pressure: {data.pres}</Typography> : null}
        {data.firstChangeTime ? <Typography color="textSecondary">First Change Time: {data.firstChangeTime}</Typography> : null}
      </Fragment>
    );
  };

  const handleFloorPlanChange = (e) => {
    console.log(e.target.value);
    setMap({ ...map, url: e.target.value });
  };

  if (!loaded) return <PageLoadingView />;
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Paper className="paper-with-padding">
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="floor-plan-select-label">Floor Plan</InputLabel>
                <Select
                  labelId="floor-plan-select-label"
                  value={map.url}
                  onChange={handleFloorPlanChange}
                  displayEmpty
                  // inputProps={{ "aria-label": "Without label" }}
                >
                  {floorPlanUrls.map((floorPlan, index) => (
                    <MenuItem key={`${index}-${floorPlan.label}`} value={floorPlan.value}>
                      {floorPlan.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={1} justify="center" alignItems="center" alignContent="center">
            <Grid item>
              <ImageMapper
                src={map.url}
                map={map}
                width={600}
                onLoad={() => {}}
                onClick={onFloorMapDeviceClick}
                onMouseEnter={(area) => setHoveredArea(area)}
                onMouseLeave={(area) => setHoveredArea(null)}
                // onMouseMove={onFloorMapDeviceHover}
                onImageClick={onFloorMapImageClick}
                // onImageMouseMove={(evt) => this.moveOnImage(evt)}
              />
            </Grid>
          </Grid>
          <Grid container justify="flex-end">
            <Grid item>
              {hoveredArea ? (
                <Fragment>
                  <Typography color="primary">Device {hoveredArea.deviceId}</Typography>
                  {renderFloorMapHoverData()}
                </Fragment>
              ) : null}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <ExperimentSettting
          name={name}
          onNameChange={setName}
          from={from}
          to={to}
          onFromChange={setFrom}
          onToChange={setTo}
          dosers={dosers}
          receivers={receivers}
          selectDevice={selectDevice}
          onDoserChange={handleDoserChange}
          onReceiverChange={handleReceiverChange}
          onReset={handleExperimentReset}
          onConfirm={handleExperimentSave}
          canEditSelectedDevice={action === "create"}
          canCalculateAvgPres={action === "create"}
          onCalculateAvgPres={handleCalculateAvgPres}
          onFloorMapEdit={handleSelectFloorMapDevice}
          onFloorMapRemove={handleRemoveFloorMapDevice}
          buttonText={buttonText}
          error={error}
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.system.user,
  };
};

export default connect(mapStateToProps, null)(withDialog(withSnackbar(ExperimentCreatePannel)));
