import React, { Component } from 'react';
import { Typography } from '@material-ui/core';

import { TRACER_GAS_WORKSPACE_VERSION } from '../utils/constants';
import AuthPanel from '../containers/AuthPanel/AuthPanel';

class LandingPage extends Component {
  render() {
    return (
      <div style={{ margin: 20}}>
        <Typography variant="h4">Tracer Gas Workspace</Typography>
        <Typography variant="caption" gutterBottom>{TRACER_GAS_WORKSPACE_VERSION}</Typography>
        <AuthPanel />
      </div>
    );
  }
}

export default LandingPage;
