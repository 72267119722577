import React from "react";
import moment from "moment";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Brush, ReferenceArea } from "recharts";

const CustomDot = (props) => {
  const { cx, cy, r = 2, color, stroke, strokeWidth = 1.5 } = props;
  return <circle cx={cx} cy={cy} r={r} stroke={stroke} strokeWidth={strokeWidth} fill={color} />;
};

// const CustomTooltips = (props) => {
//   const { active, payload, label } = props;
//   if (!active) return;
//   return (
//     <div className="custom-tooltip">
//       <p className="label">{`${label} : ${payload[0].value}`}</p>
//       <p className="intro">{this.getIntroOfPage(label)}</p>
//       <p className="desc">Anything you want can be displayed here.</p>
//     </div>
//   );
// };

const CustomLineChart = (props) => {
  const {
    isRealtime,
    data,
    xDatakey = "timestamp",
    dataKeys,
    yAxisLabel,
    yDomain = ["auto", "dataMax + 1"],
    type,
    Xtype = "category",
    tickFormatter = (unixTime) => {
      return moment(unixTime).format("MM/DD HH:mm:ss");
    },
    strokes,
    showBrush,
    strokeWidth,
    activeDot,
    dot,
    style,
    brushStroke,
    onLineChartClick,
    onMouseDown,
    onMouseMove,
    onMouseUp,
    refAreaLeft,
    refAreaRight,
    devices,
  } = props;

  const tooltipFormatter = (value, name, props) =>{
    // console.log(value, name, props);
    let deviceId = name.replace("_", "");
    let device = devices.find(d=>d.deviceId === deviceId);
    if(device && device.location){
      // return  `${value} (${device.location})`;
      return [value, `(${device.location}) ${name}`];
    }else{
      // return  `${value} (empty location)`;
      return [value, `(empty location) ${name}`];
    }
  }

  return (
    <ResponsiveContainer width={style.width} height={style.height}>
      <LineChart
        data={data}
        margin={style.margin}
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
        onClick={onLineChartClick}
      >
        <XAxis dataKey={xDatakey} tickFormatter={tickFormatter} xAxisId="0" type={Xtype} domain={["dataMin", "dataMax"]} />
        <YAxis label={yAxisLabel} domain={yDomain} yAxisId="1" />
        <YAxis domain={yDomain} yAxisId="2" orientation="right"/>
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip labelFormatter={tickFormatter} formatter={tooltipFormatter}/>
        <Legend align="right" verticalAlign="top" layout="horizontal" />
        {!!refAreaLeft && !!refAreaRight ? <ReferenceArea yAxisId="1" x1={refAreaLeft} x2={refAreaRight} strokeOpacity={0.3} /> : null}
        {dataKeys.map((key, i) => {
          let name = key.name || key;
          return <Line
            yAxisId={key.type && key.type === 'doser'? "2": "1"}
            xAxisId="0"
            key={`interactive-chart-line-${name}`}
            type={type}
            dataKey={name}
            stroke={strokes[i]}
            strokeWidth={strokeWidth}
            activeDot={activeDot}
            dot={dot && dot.customDot ? <CustomDot r={dot.r} color={strokes[i]} stroke={strokes[i]} /> : dot}
          />
        })}
        {!isRealtime && showBrush ? <Brush dataKey="timestamp" stroke={brushStroke} tickFormatter={tickFormatter} /> : null}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default CustomLineChart;
