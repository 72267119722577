import React, { Fragment, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography, Button, FormControl, FormHelperText, TextField } from "@material-ui/core";
import moment from "moment";

import DateRangePicker from "../components/DateRangePicker";
import ExperimentDeviceEditor from "../components/ExperimentDeviceEditor";

const ExperimentSettingPanel = (props) => {
  const {
    error,
    isRealtime,
    name = "",
    from = null,
    to = null,
    dosers = [],
    receivers = [],
    selectDevice = { deviceId: null, name: null },
    onChange,
    onFloorMapEdit,
    onFloorMapRemove,
    onExperimentStop,
    onExperimentReset,
    onExperimentSave,
    onExperimentDelete,
  } = props;

  const classes = useStyles();

  const handleDoserChange = (props) => (event) => {
    let newDosers = [...dosers];
    // let change = props === "select" || props === "presetValve" ? event.target.checked : event.target.value;
    let change = event.target.value;
    newDosers[+event.target.name][props] = change;
    onChange("dosers")(newDosers);
  };

  const handleReceiverChange = (props) => (event) => {
    let newReceivers = [...receivers];
    // let change = props === "select" ? event.target.checked : event.target.value;
    let change = event.target.value;
    newReceivers[+event.target.name][props] = change;
    onChange("receivers")(newReceivers);
  };

  const handleFromChange = (e) => {
    console.log(e);
    onChange("from")(e);
  };

  const handleToChange = (e) => {
    onChange("to")(e);
  };

  const handleNameChange = (e) => {
    onChange("name")(e.target.value);
  };

  return (
    <Grid item xs={12}>
      <Paper className="paper-with-padding">
      <Grid container spacing={3} justify="space-between">
        <Grid item xs={12}>
          <Typography variant="h6" component="h2" color="primary">
           Experiment Information
          </Typography>
        </Grid>
      </Grid>
        <Grid container spacing={2} justify="space-between" alignItems="flex-end">
          {/* <Grid container spacing={2} alignItems="flex-end"> */}
            <Grid item>
              <FormControl component="fieldset">
                <DateRangePicker from={from} to={to} setFrom={handleFromChange} setTo={handleToChange} />
              </FormControl>
            </Grid>
            {isRealtime && (
              <Grid item xs={2}>
                <Button fullWidth variant="contained" onClick={onExperimentStop}>
                  Stop
                </Button>
              </Grid>
            )}
          {/* </Grid> */}
          <Grid item xs={12}>
            <FormControl component="fieldset" fullWidth>
              <TextField id={`experiment-name`} label="Experiment Name" value={name} onChange={handleNameChange} fullWidth size="small" />
            </FormControl>
          </Grid>
          <ExperimentDeviceEditor
            selectDevice={selectDevice}
            dosers={dosers}
            receivers={receivers}
            onDoserChange={handleDoserChange}
            onReceiverChange={handleReceiverChange}
            canEditSelectedDevice={false}
            onFloorMapEdit={onFloorMapEdit}
            onFloorMapRemove={onFloorMapRemove}
            showFloorMapRemove={false}
            showValvePreset={false}
          />
          <Grid item xs={12}>
            <FormControl>
              <FormHelperText id="errorForm" required error={!!error} className={classes.helperText}>
                {!!error ? `* ${error}` : ""}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2} justify="center" alignItems="flex-end">
          <Grid item xs={3}>
            <Button fullWidth variant="contained" onClick={onExperimentDelete}>
              Archive
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button fullWidth variant="contained" color="primary" onClick={onExperimentReset}>
              Reset
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button fullWidth variant="contained" color="secondary" onClick={onExperimentSave}>
              Update Experiment
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  label: {
    padding: "16px 0px 16px 0px",
  },
  helperText: {
    fontSize: "1rem",
  },
}));

export default ExperimentSettingPanel;
