import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography, Button, FormControl, Select, MenuItem } from "@material-ui/core";
import moment from "moment";
import envars from "../envars";
import { primaryMain, secondaryMain, chartTheme, floorMapDefault } from "../Themes";
import api, { handleApiFailureWithDialog } from "../utils/api";
import { DOSER_TYPE, RECEIVER_TYPE } from "../utils/constants";
import useInterval from "../utils/use-interval";
import { withSnackbar } from "./SnackbarManager";
import { withDialog } from "./DialogManager";

import PageLoadingView from "../components/PageLoadingView/PageLoadingView";
import CustomLineChart from "../components/CustomLineChart";

import convertPivotName from '../utils/convert-pivot-name';

// const STROKES_COLOR = ["#5B2C6F", "#633974", "#76448A", "#884EA0", "#9B59B6", "#AF7AC5", "#C39BD3", "#D7BDE2"];
const SELECT_COLOR = "#5B2C6F";
const UNSELECT_COLOR = "#EBDEF0";

const ExperimentRealtimeDataChart = (props) => {
  const { from, to, receivers, dosers, isRealtime, setIsRealtime, onFloorMapChange } = props;

  // const devices = receivers.concat(dosers);
  const devices = receivers;
  const defaultStrokes = devices.map((d, i) => chartTheme.primary[i % chartTheme.primary.length]);
  const dataKeys = devices.map((r) => `_${r.deviceId}`);
  const legendKeys = devices.map((r) => r.name);

  const [dataInitLoaded, setDataInitLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [lastTime, setLastTime] = useState(from ); // - 5 * 60 * 1000

  const [storkes, setStorkes] = useState(defaultStrokes);
  const [selectDevice, setSelectDevice] = useState(null);

  const timeInterval = 5 * 1000; // 10000

  useEffect(() => {
    fetchData();
  }, []);

  useInterval(
    async () => {
      await fetchData();
    },
    timeInterval,
    lastTime
  );

  const fetchData = async () => {
    if (!isRealtime) return false;
    if (moment() >= moment(to)) {
      setIsRealtime(false);
      return;
    }

    let reciverIds = receivers.map((r) => r.deviceId);
    let doserIds = dosers.map(d=>d.deviceId);
    let query = `receivers=${reciverIds}&dosers=${doserIds}&from=${moment(lastTime).valueOf()}`;
    let apiResult = await api("get", `${envars.telemetryServiceUrl}/r134a?${query}`);
    if (apiResult.data.success) {
      let ppmResults = apiResult.data.result.ppmData;
      let pressureIndex = apiResult.data.result.pressure;
      if (ppmResults && ppmResults.length > 0) {
        const lastDataTime = moment(ppmResults[ppmResults.length - 1].timestamp).valueOf();
        setLastTime(lastDataTime);

        ppmResults.sort((a, b) => {
          return moment(a.timestamp).valueOf() - moment(b.timestamp).valueOf();
        });
        // console.log(ppmResults);
        ppmResults = convertPivotName(ppmResults, devices); 
        let ppmData;
        if (!dataInitLoaded) {
          ppmData = [...ppmResults];
        } else {
          ppmData = [...data];
          for(let dataPoint of ppmResults){
            let idx = ppmData.findIndex(d=>d.timestamp === dataPoint.timestamp);
            if(idx > -1){
              ppmData[idx] = dataPoint;
            }else{
              ppmData.push(dataPoint);
            }
          }
        }

        let emptyDataTime = {};
        for (let key of legendKeys) {
          emptyDataTime[key] = [];
        }

        for (let i = 0; i < ppmData.length; i++) {
          ppmData[i].timestamp = moment(ppmData[i].timestamp).valueOf();
          for (let key of legendKeys) {
            if (!ppmData[i][key]) {
              emptyDataTime[key].push(i);
            } else {
              const currentValue = +parseFloat(ppmData[i][key]).toFixed(2);
              ppmData[i][key] = currentValue;
              let emptyDataIdx = emptyDataTime[key];

              if (emptyDataIdx.length > 0) {
                let lastNonEmptyIdx = emptyDataIdx[0] === 0 ? 0 : emptyDataIdx[0] - 1;
                let lastPpmData = ppmData[lastNonEmptyIdx][key];
                let lastEmptyIdx = emptyDataIdx[emptyDataIdx.length - 1];
                if (i === lastEmptyIdx + 1) {
                  let avgValue = (lastPpmData + currentValue) / 2;
                  for (let j of emptyDataIdx) {
                    ppmData[j][key] = avgValue;
                  }
                  emptyDataTime[key] = [];
                }
              }
            }
          }
        }
        setData(ppmData);
      
        const checkAirChange = (value, min) => value / min >= 1.5;
        let deviceFloorMapAreas = [];
        let lastData = ppmData[ppmData.length - 1];
        for (let device of receivers) {
          let area = {
            deviceId: device.deviceId,
            name: device.name
          };
          let key = `_${device.deviceId}`;
          let min = Math.min(...ppmData.map((d) => d[key]).filter((d) => d));
          area.preFillColor = checkAirChange(lastData[key], min) ? primaryMain : floorMapDefault;
          area.data = {
            timestamp: moment(lastData.timestamp).format("YYYY/MM/DD HH:mm:ss"),
            r134a: lastData[key] || null,
          };
          let firstChangeTime = ppmData.find((d) => checkAirChange(d[key], min));
          if (firstChangeTime) {
            area.data.firstChangeTime = moment(firstChangeTime.timestamp).format("YYYY/MM/DD HH:mm:ss");
          }
          if (pressureIndex && pressureIndex[device.deviceId]) {
            let currentPres = pressureIndex[device.deviceId];
            let state = currentPres >= 0 ? "+" : "-";
            area.data.pres = `(${state}) ${+Math.abs(currentPres).toFixed(2)}`;
          }
          deviceFloorMapAreas.push(area);
        }

        onFloorMapChange(deviceFloorMapAreas);
      }
      if (!dataInitLoaded) setDataInitLoaded(true);
    } else {
      handleApiFailureWithDialog(props.requestDialog, apiResult);
    }
  };

  const handleLineChartClick = (e) => {
    if (e && e.dataKey) {
      let value = e.dataKey;
      let selectedDevice = devices.find((device) => device.name === value);
      let selectedLineIdx = legendKeys.findIndex((k) => k === value);
      let newStorkes = [...storkes];
      newStorkes = newStorkes.map((stroke, i) => (i === selectedLineIdx ? SELECT_COLOR : UNSELECT_COLOR));
      setStorkes(newStorkes);
      setSelectDevice(selectedDevice);
    }
  };

  const resetLineChartClick = () => {
    setStorkes(defaultStrokes);
    setSelectDevice(null);
  };

  const renderChart = () => {
    if (!dataInitLoaded) {
      return (
        <Grid container justify="center" alignItems="center">
          <PageLoadingView />
        </Grid>
      );
    } else {
      return (
        <Fragment>
          <Grid item xs={12}>
            <CustomLineChart
              key="r134a-realtime"
              data={data}
              type="monotone"
              Xtype="number"
              yAxisLabel={{ value: "R134A(ppm)", angle: -90, position: "insideLeft" }}
              yDomain={["auto", "dataMax + 15"]}
              dataKeys={devices.map((r) => ({name:r.name, type: r.type}))}
              strokes={storkes}
              storkeWidth={1.5}
              activeDot={{ r: 3, onClick: handleLineChartClick }}
              dot={false}
              style={{ width: "100%", height: 550, margin: { top: 0, right: 40, left: 0, bottom: 16 } }}
              isRealtime={isRealtime}
              showBrush={true}
              brushStroke={SELECT_COLOR}
              onLineChartClick={handleLineChartClick}
              devices={devices}
            />
          </Grid>
        </Fragment>
      );
    }
  };

  return (
    <Paper className="paper-with-padding">
      <Grid container spacing={3} justify="space-between">
        <Grid item xs={12}>
          <Typography variant="h6" component="h2" color="primary">
          Tracer Gas R134A Measurement Device - Realtime
          </Typography>
        </Grid>
      </Grid>
      {renderChart()}
      <Grid container spacing={1} justify="flex-end">
        <Grid item>
          <Button fullWidth variant="contained" onClick={resetLineChartClick}>
            Reset Selected Line
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.system.user,
  };
};

export default connect(mapStateToProps, null)(withDialog(withSnackbar(ExperimentRealtimeDataChart)));
