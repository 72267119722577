import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { Grid, Paper, Typography} from "@material-ui/core";

import DashboardDevicePanel from "../containers/DashboardDevicePanel";
import DashboardExperimentPanel from "../containers/DashboardExperimentPanel";

const DashboardPage = (props) => {
  const gridWeight = { xs: 12, sm: 12, md: 6, lg: 4, xl: 2 };

  return (
    <div className="paper-with-padding">
      <Typography variant="h4" component="h2" gutterBottom>
        Dashboard
      </Typography>
      <Grid container spacing={1}>
        <Grid item {...gridWeight}>
          <DashboardDevicePanel />
        </Grid>
        <Grid item {...gridWeight}>
          <DashboardExperimentPanel />
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    user: state.system.user
  };
};

export default connect(mapStateToProps, null)(DashboardPage);

