import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import envars from "../envars";
import { Paper, Typography, Grid, FormControl, Select, MenuItem, InputLabel, makeStyles } from "@material-ui/core";
import { styler } from "react-timeseries-charts";
import { TimeSeries } from "pondjs";

import api, { handleApiFailureWithDialog } from "../utils/api";
import useInterval from "../utils/use-interval";
import { DOSER_TYPE, RECEIVER_TYPE, DEVICE_STATUS } from "../utils/constants";

import PageLoadingView from "../components/PageLoadingView/PageLoadingView";
import CustomTimeSeriesChart from "../components/CustomTimeSeriesChart";
import CustomTimeSeriesChannelChart from "../components/CustomTimeSeriesChannelChart";

import { withSnackbar } from "../containers/SnackbarManager";
import { withDialog } from "../containers/DialogManager";
import toHKTimeString from "../utils/to-hk-time-string";
import { themeStyle, primaryMain } from "../Themes";


const style = styler([
  { key: "temperature", color: "#85C1E9", width: 1.5 },
  { key: "humidity", color: "#76D7C4", width: 1.5},
  { key: "pm10", color: "#F7DC6F", width: 1.5},
  { key: "pm25", color: "#F8C471", width: 1.5},
  // { key: "co2", color: "#F1948A", width: 1.5},
  { key: "voc", color: "#C39BD3", width: 1.5},
]);

const defaultChannels = {
  temperature: { units: "°C", label: "Temperature", format: ",.2f", series: null, show: true },
  humidity: { units: "%", label: "Humidity", format: ",.2f", series: null, show: true },
  pm10: { units: "μg/m3", label: "PM 10", format: ",.2f", series: null, show: true },
  pm25: { units: "μg/m3", label: "PM 2.5", format: ",.2f", series: null, show: true },
  // co2: { units: "ppm", label: "CO2", format: ",.2f", series: null, show: true },
  voc: { units: "μg/m3", label: "VOC", format: ",.2f", series: null, show: true }
}

const IAQDataPanel = (props) => {
  const { device: propsDevice, from, dayInterval } = props;

  const [dataInitLoaded, setDataInitLoaded] = useState(false);
  const [device, setDevice] = useState(propsDevice);
  const [data, setData] = useState([]);
  const [lastTime, setLastTime] = useState(from);
  const [lastRecordTime, setLastRecordTime] =  useState(null);
  const [channels, setChannels] = useState(JSON.parse(JSON.stringify(defaultChannels)));
  const channelNames = ["temperature", "humidity", "pm10", "pm25", "voc"];    // remove co2,co,hcn 

  const timeInterval = 30 * 1000; // 10000

  // useEffect(() => {
  //   fetchData();
  // }, []);

  useEffect(() => {
    if (!dataInitLoaded) fetchData();
  }, [dataInitLoaded]);

  useEffect(() => {
    resetData();
  }, [propsDevice, from]);

  useInterval(
    async () => {
      await fetchData();
    },
    timeInterval,
    lastTime
  );

  const resetData = () => {
    // console.log("reset Data");
    setChannels(JSON.parse(JSON.stringify(defaultChannels)));
    setLastRecordTime(null);
    setLastTime(from);
    setDevice(propsDevice);
    setData([]);
    setDataInitLoaded(false);
  };

  const fetchData = async () => {
    if (!device) {
      setDataInitLoaded(true);
      return;
    };
    const deviceId = device.deviceId;
    const query = `from=${lastTime}`;
    // console.log("IAQ Pannel: fetch IAQ data: ", dataInitLoaded, device.deviceId, lastTime, data.length);

    const apiResult = await api("get", `${envars.telemetryServiceUrl}/iaq/${deviceId}?${query}`);
    if (apiResult.data.success) {
      let iaqResults = apiResult.data.result.data;
      if (iaqResults && iaqResults.length > 0) {
        const lastDataTime = moment(iaqResults[iaqResults.length - 1].timestamp, "YYYY-MM-DDTHH:mm:ss").add(1, "s");
        setLastTime(lastDataTime.valueOf());
        // console.log("Update Last data time ", lastDataTime.valueOf());

        iaqResults.sort((a, b) => {
          return moment(a.timestamp, "YYYY-MM-DDTHH:mm:ss").valueOf() - moment(b.timestamp, "YYYY-MM-DDTHH:mm:ss").valueOf();
        });
        iaqResults.forEach((d) => (d.timestamp = moment(d.timestamp, "YYYY-MM-DDTHH:mm:ss").valueOf()));

        let iaqData;
        if (!dataInitLoaded) {
          iaqData = [...iaqResults];
        } else {
          iaqData = [...data];
          iaqResults.forEach((result) => {
            iaqData.push(result);
          });
        }
        iaqData = iaqData.filter((d) => moment().diff(moment(d.timestamp), "d") <= dayInterval);
        setData(iaqData);
        setLastRecordTime(toHKTimeString(iaqData[iaqData.length-1].timestamp));
        generateChannels(iaqData);
      }
      if (!dataInitLoaded) setDataInitLoaded(true);
    } else {
      handleApiFailureWithDialog(props.requestDialog, apiResult);
    }
  };

  const generateChannels = (iaqData) => {
    if(iaqData.length <= 0) return;
    let updateChanel = {...channels};
    for (let name of channelNames) {
      let series = new TimeSeries({ name, columns: ["time", name], points: iaqData.map((d) => [d.timestamp, d[name]]) });
      updateChanel[name].series = series;
      updateChanel[name].avg = series.avg(name)? +series.avg(name).toFixed(2): null;
      updateChanel[name].min = series.min(name)? +series.min(name).toFixed(2): null;
      updateChanel[name].max = series.max(name)? +series.max(name).toFixed(2): null;
    }
    setChannels(updateChanel);
  };


  if (!device) return <div />;
  if (!dataInitLoaded) return <PageLoadingView />;
  return (
    <Grid container spacing={2} style={{paddingTop: 32}}>
      <Grid item xs={12}>
        {/* <CustomTimeSeriesChart 
          title="Temperature"
          name="temperature"
          series={channels['temperature'].series}
          style={styler([{key:'temperature', color: themeStyle.primaryMain, width:1 }])}
          min={channels['temperature'].max}
          max={channels['temperature'].min}
        /> */}
        <CustomTimeSeriesChannelChart 
          name="iaq"
          channels={channels}
          channelNames={channelNames}
          lastRecordTime={lastRecordTime}
          style={style}
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.system.user,
  };
};

export default connect(mapStateToProps, null)(withDialog(withSnackbar(IAQDataPanel)));
