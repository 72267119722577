import React from "react";
import { Grid, Paper, Typography, FormControl, TextField, FormHelperText, Button } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";

const DeviceInfoCard = (props) => {
  const {name, location, onChange, onSave} = props;
  return (
    <Paper className="paper-with-padding">
      <Typography variant="h6" gutterBottom>
        Device Info
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12}>
          <FormControl required fullWidth>
            <TextField
              label="Device Name"
              id="name"
              name="name"
              // autoFocus 
              required
              value={name}
              onChange={onChange}
              fullWidth
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormControl required fullWidth>
            <TextField
              label="Location"
              id="location"
              name="location"
              // autoFocus
              required
              value={location}
              onChange={onChange}
              fullWidth
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
          <Grid item>
            <Button fullWidth variant="contained" color="secondary" onClick={onSave}>
              <SaveIcon />
              Save
            </Button>
          </Grid>
        </Grid>
    </Paper>
  );
};

export default DeviceInfoCard;