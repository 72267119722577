import React, { useState, useEffect, Fragment } from "react";
import moment from "moment";
import "moment-duration-format";
import { Grid, Typography } from "@material-ui/core";
import {
  Charts,
  ChartContainer,
  ChartRow,
  YAxis,
  LineChart,
  Resizable,
  Legend,
  Baseline,
  LabelAxis,
  ValueAxis,
  Brush,
  AreaChart,
} from "react-timeseries-charts";
import toHKTimeString from "../utils/to-hk-time-string";
import { TimeRange } from "pondjs";

const chartStyle = {
  borderStyle: "solid",
  borderWidth: 1,
  borderColor: "#DDD",
  paddingTop: 10,
  marginBottom: 10,
};

const brushStyle = {
  boxShadow: "inset 0px 2px 5px -2px rgba(189, 189, 189, 0.75)",
  background: "#FEFEFE",
  paddingTop: 10,
  opacity:0.5
};

const baselineStyles = {
  stroke: "steelblue",
  opacity: 0.5,
  width: 0.25,
};

const CustomTimeSeriesChannelChart = (props) => {
  const { name, channels, channelNames, style, lastRecordTime } = props;
  const [width, setWidth] = useState(null);
  const [timeRange, setTimeRange] = useState(null);
  const [brushRange, setBrushRange] = useState(null);
  const [tracker, setTracker] = useState(null);
  const [minTime, setMinTime] = useState(0);
  const [maxTime, setMaxTime] = useState(0);

  // const initialRange =new TimeRange([75 * 60 * 1000, 125 * 60 * 1000])
  const minDuration = 60 * 1000;

  useEffect(() => {
    init();
  }, [channels]);

  const init = () => {
    if(!channels[channelNames[0]].series) return;
    setTimeRange(channels[channelNames[0]].series.range());
    setBrushRange(channels[channelNames[0]].series.range());
    setMinTime(channels[channelNames[0]].series.range().begin());
    setMaxTime(channels[channelNames[0]].series.range().end());
    setTracker(null);
  };

  const handleTrackerChanged = (t) => {
    setTracker(t);
  };

  const handleTimeRangeChange = (timerange) => {
    if (timerange) {
      setTimeRange(timerange);
      setBrushRange(timerange);
    } else {
      setTimeRange(channels[channelNames[0]].series.range());
      setBrushRange(null);
    }
  };

  const handleChartResize = (width) => {
    setWidth(width);
  };

  const renderChannelsChart = () => {
    const rows = [];

    for (let channelName of channelNames) {
      const charts = [];
      let series = channels[channelName].series;

      charts.push(
        <LineChart key={`line-${channelName}`} axis={`${channelName}_axis`} series={series} columns={[channelName]} style={style} breakLine />
      );
      charts.push(<Baseline key={`baseline-${channelName}`} axis={`${channelName}_axis`} style={baselineStyles} value={channels[channelName].avg} />);

      // Get the value at the current tracker position for the ValueAxis
      let value = "--";
      if (tracker) {
        // const approx = (+tracker - +timeRange.begin()) / (+timeRange.end() - +timeRange.begin());
        // const ii = Math.floor(approx * series.size());
        // const i = series.bisect(new Date(tracker), ii);
        // const v = i < series.size() ? series.at(i).get(channelName) : null;
        // if (v) {
        //   value = parseInt(v, 10);
        // }
        const index = series.bisect(tracker);
        let newValue = series.at(index).get(channelName);
        if(newValue){
          value = +newValue.toFixed(2);
        }
      }

      // Get the summary values for the LabelAxis
      const summary = [
        { label: "Max", value: channels[channelName].max },
        { label: "Avg", value: channels[channelName].avg },
        { label: "Min", value: channels[channelName].min },
      ];

      rows.push(
        <ChartRow height="150" visible={channels[channelName].show} key={`row-${channelName}`}>
          <LabelAxis
            id={`${channelName}_axis`}
            label={channels[channelName].label}
            values={summary}
            min={channels[channelName].min - 5}
            max={channels[channelName].max + 5}
            width={120}
            type="linear"
            format=",.2f"
          />
          <Charts>{charts}</Charts>
          <ValueAxis id={`${channelName}_valueaxis`} value={value} detail={channels[channelName].units} width={80} min={0} max={35} />
        </ChartRow>
      );
    }

    return (
      <ChartContainer
        timeRange={timeRange}
        // format="relative"
        showGrid={false}
        enablePanZoom={true}
        maxTime={maxTime}
        minTime={minTime}
        minDuration={minDuration}
        trackerPosition={tracker}
        onTimeRangeChanged={handleTimeRangeChange}
        onChartResize={(width) => handleChartResize(width)}
        onTrackerChanged={handleTrackerChanged}
      >
        {rows}
      </ChartContainer>
    );
  };

  const renderBrush = () => {
    return (
      <ChartContainer
        timeRange={channels && channels[channelNames[0]] ? channels[channelNames[0]].series.range() : null}
        // format="relative"
        trackerPosition={tracker}
      >
        <ChartRow height="70" debug={false}>
          <Brush timeRange={brushRange} allowSelectionClear onTimeRangeChanged={handleTimeRangeChange} />
          <YAxis label="Temp(°C)" id="axis1" min={channels[channelNames[0]].min} max={channels[channelNames[0]].max} width={70} type="linear" format="d" />
          <Charts>
            <AreaChart
              axis="axis1"
              style={style.areaChartStyle()}
              columns={{ up: [channelNames[0]], down: [] }}
              series={channels[channelNames[0]].series}
            />
          </Charts>
        </ChartRow>
      </ChartContainer>
    );
  };

  if(!timeRange) return <div />; 
  return (
    <Fragment>
      <Grid container justify="space-between">
        <Grid item sm={8}>
          <Legend
            type="line"
            style={style}
            categories={channelNames.map((channelName) => ({ key: channelName, label: channels[channelName].label }))}
          />
        </Grid>
        <Grid item>
          <Typography color="secondary">
            Timestamp: {tracker ? moment(new Date(tracker)).format("YYYY/MM/DD HH:mm:ss") : "----/--/-- --:--:--"}
          </Typography>
          <Typography color="textSecondary" variant="caption">
            Last Record Timestamp: {lastRecordTime? lastRecordTime: "----/--/-- --:--:--"}
          </Typography>
        </Grid>
      </Grid>
      <div className="row">
        <div className="col-md-12" style={chartStyle}>
          <Resizable>{renderChannelsChart()}</Resizable>
        </div>
      </div>
      <div className="row" style={brushStyle}>
        <div className="col-md-12">
          <Resizable>{renderBrush()}</Resizable>
        </div>
      </div>
    </Fragment>
  );
};

export default CustomTimeSeriesChannelChart;
