import React from "react";
import { makeStyles} from "@material-ui/core";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";
import { themeStyle } from "../Themes";

const DateToggleButtonGroup = (props) => {
  const classes = useStyles();
  const { value, onDateSelect } = props;
  return (
    <ToggleButtonGroup className={classes.buttonGroup} value={value} size="small">
      <ToggleButton
        value={1}
        onClick={() => {
          onDateSelect(1);
        }}
      >
        1 Days
      </ToggleButton>
      <ToggleButton
        value={3}
        onClick={() => {
          onDateSelect(3);
        }}
      >
        3 Days
      </ToggleButton>
      <ToggleButton
        value={7}
        onClick={() => {
          onDateSelect(7);
        }}
      >
        7 Days
      </ToggleButton>
      <ToggleButton
        value={30}
        onClick={() => {
          onDateSelect(30);
        }}
      >
        1 Month
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    "& button": {
      color: themeStyle.palette.secondary.main,
      border: `1px solid ${themeStyle.palette.secondary.main}`,
    },
    "& span": {
      color: themeStyle.palette.secondary.main,
    },
  },
}));

export default DateToggleButtonGroup;