import React, { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { List, ListItem, ListItemText, ListItemIcon, ListSubheader, Divider } from "@material-ui/core";

import DashboardPaper from "../components/DashboardPaper";
import DeviceStatusIcon from "../components/DeviceStatusIcon";

import envars from "../envars";
import api from "../utils/api";
import { DOSER_TYPE, RECEIVER_TYPE } from "../utils/constants";
// import useInterval from "../utils/use-interval";
import toHKTimeString from "../utils/to-hk-time-string";

const DashboardExperimentPanel = (props) => {
  // const classes = useStyles();
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(null);

  const [count, setCount] = useState(0);
  const [experiments, setExperiments] = useState([]);

  useEffect(() => {
    if (!loaded) fetchData();
  }, [loaded]);

  const fetchData = async () => {
    // console.log(lastUpdateTime);
    try {
      let apiResult = await api("get", `${envars.telemetryServiceUrl}/experiments?page=1&size=10`, null);
      if (apiResult.data.success) {
        let experiments = apiResult.data.result.experiments;
        setExperiments(experiments);
        setCount(apiResult.data.result.count);
        setError(null);
        if (!loaded) setLoaded(true);
      }
    } catch (e) {
      // handleApiFailureWithDialog(props.requestDialog, deviceApiResult);
      setError(e);
    }
  };

  return (
    <DashboardPaper
      loaded={loaded}
      error={error}
      title="Experiments"
      onRetry={() => setLoaded(false)}
      subheader={`Total Experiment Count : ${count}`}
      detailUrl="/experiments"
    >
      <Divider />
      <List disablePadding>
        {experiments && experiments.length > 0
          ? experiments.map((experiment, i) => {
              return (
                <Fragment key={`experiment-${i}`}>
                  <Divider />
                  <ListItem>
                    <ListItemText
                      primary={experiment.name}
                      secondary={`time: ${toHKTimeString(experiment.from)} - ${toHKTimeString(experiment.to)}`}
                    />
                  </ListItem>
                </Fragment>
              );
            })
          : null}
      </List>
    </DashboardPaper>
  );
};

// const useStyles = makeStyles((theme) => ({
//   ListSubheader: {
//     paddingLeft: "0px",
//   },
// }));

const mapStateToProps = (state) => {
  return {
    user: state.system.user,
  };
};

export default connect(mapStateToProps, null)(DashboardExperimentPanel);
