import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { Paper, CardHeader, CardContent, Divider, List, ListItem, ListItemText } from '@material-ui/core';
import NextIcon from '@material-ui/icons/KeyboardArrowRight';

import PageLoadingView from '../components/PageLoadingView/PageLoadingView';
import PageErrorView from '../components/PageErrorView/PageErrorView';
// import PopUpMenu from '../containers/PopUpMenu/PopUpMenu';

const DashboardPaper = props => {
  let { loaded, error, title, subheader = null, children, detailUrl, onRetry = () => {} } = props;

  if (!loaded) {
    return (
      <Paper className="paper-with-padding">
        <CardHeader title={title} />
        <PageLoadingView noPadding />
      </Paper>
    );
  }

  if (error) {
    return (
      <Paper className="paper-with-padding">
        <CardHeader title={title} />
        <PageErrorView noPadding error={error} onRetry={onRetry} />
      </Paper>
    );
  }

  return (
    <Paper>
      <CardHeader title={title} subheader={subheader} />
      <CardContent>{children}</CardContent>
      {detailUrl ? (
        <Fragment>
          <Divider />
          <List>
            <ListItem button onClick={() => props.history.push(detailUrl)}>
              <ListItemText primary="Details" />
              <NextIcon />
            </ListItem>
          </List>
        </Fragment>
      ) : null}
    </Paper>
  );
};

export default withRouter(DashboardPaper);
