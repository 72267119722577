import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Grid, Paper, Typography, Button} from "@material-ui/core";
import moment from "moment";
import envars from "../envars";
import { primaryMain, secondaryMain, chartTheme, floorMapDefault } from "../Themes";
import api, { handleApiFailureWithDialog } from "../utils/api";
import useInterval from "../utils/use-interval";
import { withSnackbar } from "./SnackbarManager";
import { withDialog } from "./DialogManager";

import PageLoadingView from "../components/PageLoadingView/PageLoadingView";
import CustomLineChart from "../components/CustomLineChart";

import convertPivotName from '../utils/convert-pivot-name';

// const STROKES_COLOR = ["#5B2C6F", "#633974", "#76448A", "#884EA0", "#9B59B6", "#AF7AC5", "#C39BD3", "#D7BDE2"];
const SELECT_COLOR = "#5B2C6F";
const UNSELECT_COLOR = "#EBDEF0";

const ExperimentRealtimePressureChart = (props) => {
  const { from, to, receivers, dosers, isRealtime, setIsRealtime } = props;

  const avgPres = receivers[0].avgPres;
  const devices = receivers.concat(dosers);
  const defaultStrokes = devices.map((d, i) => chartTheme.primary[i % chartTheme.primary.length]);
  const dataKeys = devices.map((r) => `_${r.deviceId}`);
  const legendKeys = devices.map((r) => r.name);

  const [dataInitLoaded, setDataInitLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [lastTime, setLastTime] = useState(from ); // - 5 * 60 * 1000

  const [storkes, setStorkes] = useState(defaultStrokes);
  const [selectDevice, setSelectDevice] = useState(null);

  const timeInterval = 5 * 1000; // 10000

  useEffect(() => {
    fetchData();
  }, []);

  useInterval(
    async () => {
      await fetchData();
    },
    timeInterval,
    lastTime
  );

  const fetchData = async () => {
    let reciverIds = receivers.map((r) => r.deviceId);
    let doserIds = dosers.map(d=>d.deviceId);
    let query = `receivers=${reciverIds}&dosers=${doserIds}&from=${moment(from).valueOf()}&to=${moment(to).valueOf()}&avgPres=${avgPres}`;
    let apiResult = await api("get", `${envars.telemetryServiceUrl}/pressure?${query}`);
    if (apiResult.data.success) {
      let pressureResults = apiResult.data.result.pressure;
      if (pressureResults && pressureResults.length > 0) {
        const lastDataTime = moment(pressureResults[pressureResults.length - 1].timestamp).valueOf();
        setLastTime(lastDataTime);

        pressureResults.sort((a, b) => {
          return moment(a.timestamp).valueOf() - moment(b.timestamp).valueOf();
        });
        pressureResults = convertPivotName(pressureResults, devices);
        // console.log(pressureResults);
        let pressureData;
        if (!dataInitLoaded) {
          pressureData = [...pressureResults];
        } else {
          pressureData = [...data];
          for(let dataPoint of pressureResults){
            let idx = pressureData.findIndex(d=>d.timestamp === dataPoint.timestamp);
            if(idx > -1){
              pressureData[idx] = dataPoint;
            }else{
              pressureData.push(dataPoint);
            }
          }
        }

        let emptyDataTime = {};
        for (let key of legendKeys) {
          emptyDataTime[key] = [];
        }

        for (let i = 0; i < pressureData.length; i++) {
          pressureData[i].timestamp = moment(pressureData[i].timestamp).valueOf();
          for (let key of legendKeys) {
            if (!pressureData[i][key]) {
              emptyDataTime[key].push(i);
            } else {
              const currentValue = +parseFloat(pressureData[i][key]).toFixed(2);
              pressureData[i][key] = currentValue;
              let emptyDataIdx = emptyDataTime[key];

              if (emptyDataIdx.length > 0) {
                let lastNonEmptyIdx = emptyDataIdx[0] === 0 ? 0 : emptyDataIdx[0] - 1;
                let lastpressureData = pressureData[lastNonEmptyIdx][key];
                let lastEmptyIdx = emptyDataIdx[emptyDataIdx.length - 1];
                if (i === lastEmptyIdx + 1) {
                  let avgValue = (lastpressureData + currentValue) / 2;
                  for (let j of emptyDataIdx) {
                    pressureData[j][key] = avgValue;
                  }
                  emptyDataTime[key] = [];
                }
              }
            }
          }
        }

        setData(pressureData);
      }
      if (!dataInitLoaded) setDataInitLoaded(true);
    } else {
      handleApiFailureWithDialog(props.requestDialog, apiResult);
    }
  };

  const handleLineChartClick = (e) => {
    if (e && e.dataKey) {
      let value = e.dataKey;
      let selectedDevice = devices.find((receiver) => receiver.name === value);
      let selectedLineIdx = legendKeys.findIndex((k) => k === value);
      let newStorkes = [...storkes];
      newStorkes = newStorkes.map((stroke, i) => (i === selectedLineIdx ? SELECT_COLOR : UNSELECT_COLOR));
      setStorkes(newStorkes);
      setSelectDevice(selectedDevice);
    }
  };

  const resetLineChartClick = () => {
    setStorkes(defaultStrokes);
    setSelectDevice(null);
  };

  const renderChart = () => {
    if (!dataInitLoaded) {
      return (
        <Grid container justify="center" alignItems="center">
          <PageLoadingView />
        </Grid>
      );
    } else {
      return (
        <Fragment>
          <Grid item xs={12}>
            <CustomLineChart
              key="pressure-realtime"
              data={data}
              type="monotone"
              Xtype="number"
              yAxisLabel={{ value: "Pressure", angle: -90, position: "insideLeft" }}
              yDomain={["auto", "dataMax + 15"]}
              dataKeys={legendKeys}
              strokes={storkes}
              storkeWidth={1.5}
              activeDot={{ r: 3, onClick: handleLineChartClick }}
              dot={false}
              style={{ width: "100%", height: 380, margin: { top: 0, right: 40, left: 0, bottom: 16 } }}
              isRealtime={isRealtime}
              showBrush={true}
              brushStroke={SELECT_COLOR}
              onLineChartClick={handleLineChartClick}
              devices={devices}
            />
          </Grid>
        </Fragment>
      );
    }
  };

  return (
    <Paper className="paper-with-padding">
      <Grid container spacing={3} justify="space-between">
        <Grid item xs={12}>
          <Typography variant="h6" component="h2" color="primary">
          Tracer Gas Pressure - Realtime
          </Typography>
        </Grid>
      </Grid>
      {renderChart()}
      <Grid container spacing={1} justify="flex-end">
        <Grid item>
          <Button fullWidth variant="contained" onClick={resetLineChartClick}>
            Reset Selected Line
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.system.user,
  };
};

export default connect(mapStateToProps, null)(withDialog(withSnackbar(ExperimentRealtimePressureChart)));
