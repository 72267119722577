import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography, Button, List, ListItem, ListItemAvatar, Avatar, ListItemText, ListItemSecondaryAction } from "@material-ui/core";

import SubjectIcon from "@material-ui/icons/Subject";
import GetAppIcon from "@material-ui/icons/GetApp";
import FolderIcon from "@material-ui/icons/Folder";
import PageLoadingView from "./PageLoadingView/PageLoadingView";

const ExportDataDownloadList = (props) => {
  const { exportFiles, exportFileLoading } = props;

  const FileItem = (fileName, url) => {
    return (
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <FolderIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={fileName} />
        <ListItemSecondaryAction>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => {
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", fileName);
              document.body.appendChild(link);
              link.click();
            }}
          >
            <GetAppIcon />
          </Button>
        </ListItemSecondaryAction>
      </ListItem>
    );
  };

  return (
    <Paper className="paper-with-padding">
      <Grid item xs>
        <Typography variant="body1" component="h2">
          <SubjectIcon />
        </Typography>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {exportFileLoading ? (
            <PageLoadingView />
          ) : (
            <List>
              {exportFiles.map((file, i) => (
                <Grid item xs={12} key={`exxport-file-${i}`}>
                  {FileItem(file.filename, file.tempUrl)}
                </Grid>
              ))}
            </List>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ExportDataDownloadList;
