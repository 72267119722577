import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { List, ListItem, ListItemText, ListItemIcon, ListSubheader, Divider } from "@material-ui/core";

import DashboardPaper from "../components/DashboardPaper";
import DeviceStatusIcon from "../components/DeviceStatusIcon";

import envars from "../envars";
import api from "../utils/api";
import { DOSER_TYPE, RECEIVER_TYPE } from "../utils/constants";
import useInterval from "../utils/use-interval";
import toHKTimeString from "../utils/to-hk-time-string";
import getDeivceStatus from "../utils/get-device-status";

const DashboardDevicePanel = (props) => {
  const classes = useStyles();
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(null);

  const [lastUpdateTime, setLastUpdatetime] = useState(null);
  const [count, setCount] = useState(0);
  const [devices, setDevices] = useState([]);

  const timeInterval = 30 * 1000; // 10000

  useEffect(() => {
    if (!loaded) fetchData();
  }, [loaded]);

  useInterval(
    async () => {
      await fetchData();
    },
    timeInterval,
    lastUpdateTime
  );

  const fetchData = async () => {
    try {
      let deviceApiResult = await api("get", `${envars.deviceServiceUrl}/devices?page=1&size=10000`, null);
      if (deviceApiResult.data.success) {
        let devices = deviceApiResult.data.result.devices;
        if(devices){
          for (let device of devices) {
            device.status = getDeivceStatus(device);
          }
        }
        setLastUpdatetime(moment().valueOf());
        setDevices(devices);
        setCount(deviceApiResult.data.result.count);
        setLoaded(true);
        setError(null);
      }
    } catch (e) {
      // handleApiFailureWithDialog(props.requestDialog, deviceApiResult);
      setError(e);
    }
  };

  return (
    <DashboardPaper
      loaded={loaded}
      error={error}
      title="Devices"
      onRetry={() => setLoaded(false)}
      subheader={`Last Update Time: ${toHKTimeString(lastUpdateTime)}`}
      detailUrl="/devices"
    >
      {`Total Device Count : ${count}`}
      <Divider />
      <List disablePadding subheader={<ListSubheader className={classes.ListSubheader}>Valve Control Device</ListSubheader>}>
        {devices && devices.length >= 0? devices
              .filter((d) => d.type === DOSER_TYPE)
              .map((device, i) => {
                return (
                  <ListItem key={`doser-${i}`}>
                    <ListItemText primary={`Device ID ${device.deviceId}`} secondary={device.name || "(empty name)"} />
                    <ListItemIcon>
                      <DeviceStatusIcon status={device.status} />
                    </ListItemIcon>
                  </ListItem>
                );
              })
          : null}
      </List>
      <Divider />
      <List disablePadding subheader={<ListSubheader className={classes.ListSubheader}>R134A Measurement Device</ListSubheader>}>
        {devices && devices.length >= 0
          ? devices
              .filter((d) => d.type === RECEIVER_TYPE)
              .map((device, i) => {
                return (
                  <ListItem key={`receiver-${i}`}>
                    <ListItemText primary={`Device ID ${device.deviceId}`} secondary={device.name || "(empty name)"} />
                    <ListItemIcon>
                      <DeviceStatusIcon status={device.status} />
                    </ListItemIcon>
                  </ListItem>
                );
              })
          : null}
      </List>
    </DashboardPaper>
  );
};

const useStyles = makeStyles((theme) => ({
  ListSubheader: {
    paddingLeft: "0px",
  },
}));

const mapStateToProps = (state) => {
  return {
    user: state.system.user,
  };
};

export default connect(mapStateToProps, null)(DashboardDevicePanel);
