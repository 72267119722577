import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Typography,
  TablePagination,
  makeStyles,
  Grid,
  IconButton,
  Fab,
  TableContainer,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import ArchiveIcon from '@material-ui/icons/Archive';
// import queryString from "query-string";

import envars from "../envars";
import api, { handleApiFailureWithDialog } from "../utils/api";
import PageLoadingView from "../components/PageLoadingView/PageLoadingView";
// import PageErrorView from "../components/PageErrorView/PageErrorView";

import { withSnackbar } from "../containers/SnackbarManager";
import { withDialog } from "../containers/DialogManager";
import toHKTimeString from "../utils/to-hk-time-string";

const ExperimentListPage = (props) => {
  const classes = useStyles();
  const [loaded, setLoaded] = useState(false);
  // const [error, setError] = useState(null);
  const [experiments, setExperiments] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  useEffect(() => {
    if (!loaded) fetchData();
  }, [loaded]);

  const fetchData = async () => {
    let apiResult = await api("get", `${envars.telemetryServiceUrl}/experiments?page=${page}&size=${rowsPerPage}`, null);
    if (apiResult.data.success) {
      let experiments = apiResult.data.result.experiments;
      setExperiments(experiments);
      setCount(apiResult.data.result.count);
      setLoaded(true);
    } else {
      handleApiFailureWithDialog(props.requestDialog, apiResult);
    }
  };

  const onPageChangeHandler = (e, pageStartsFromZero) => {
    setPage(pageStartsFromZero + 1);
    setLoaded(false);
  };

  const onRowsPerPageChangeHandler = (e) => {
    setRowsPerPage(e.target.value);
    setLoaded(false);
  };

  const addExperimentsHandler = async () => {
    props.history.push(`/experiments/create`);
  };

  const deleteExperimentHandler = async (experimentId, experimentName) => {
    console.log(experimentId);
    props.requestDialog({
      title: "Warning",
      text: `Comfirm to Archive Experiment : ${experimentName} ? `,
      buttons: [
        {
          text: "cancle",
        },
        {
          text: "ok",
          onClick: async () => {
            let apiResult = await api("delete", `${envars.telemetryServiceUrl}/experiments/${experimentId}`);
            if (apiResult.data.success) {
              // props.history.push("/experiments");
              setLoaded(false);
            } else {
              handleApiFailureWithDialog(props.requestDialog, apiResult);
            }
          },
        },
      ],
    });
  };

  if (!loaded) return <PageLoadingView />;

  return (
    <div className="paper-with-padding">
      <Typography variant="h4" component="h2" className={classes.title}>
        Experiments
      </Typography>
      <Button onClick={addExperimentsHandler} color="primary" variant="contained" style={{ marginLeft: 8, marginBottom: 12 }}>
        <AddIcon />
        Add New
      </Button>
      <Paper className={classes.paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Start Time</TableCell>
              <TableCell>End Time</TableCell>
              <TableCell>Valve Device</TableCell>
              <TableCell>R134A Device</TableCell>
              <TableCell>Creator</TableCell>
              {/* <TableCell>Update Time</TableCell> */}
              <TableCell width="150px">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {experiments && experiments.length > 0
              ? experiments.map((experiment) => {
                  const { id, name, from, to, dosers, receivers, creator, updateTime } = experiment;
                  return (
                    <TableRow key={id}>
                      <TableCell>{name}</TableCell>
                      <TableCell>{from ? toHKTimeString(from) : null}</TableCell>
                      <TableCell>{to ? toHKTimeString(to) : null}</TableCell>
                      {/* <TableCell>{dosers ? dosers.length : null}</TableCell> */}
                      {/* <TableCell>{receivers ? receivers.length : null}</TableCell> */}
                      <TableCell style={{ whiteSpace: "normal", wordBreak: "break-word" }}>
                        {dosers ? dosers.map((d) => d.deviceId).join(" ") : null}
                      </TableCell>
                      <TableCell style={{ whiteSpace: "normal", wordBreak: "break-word" }}>
                        {receivers ? receivers.map((d) => d.deviceId).join(" ") : null}
                      </TableCell>
                      <TableCell>
                        {creator.id === props.user.id ? (
                          <Typography color="primary">{creator.username}</Typography>
                        ) : (
                          <Typography>{creator.username}</Typography>
                        )}
                      </TableCell>
                      {/* <TableCell>{toHKTimeString(updateTime)}</TableCell> */}
                      <TableCell>
                        <Grid container>
                          <Grid item xs={6}>
                            <IconButton
                              color="primary"
                              variant="contained"
                              onClick={() => {
                                props.history.push(`/experiments/${id}`);
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          </Grid>
                          <Grid item xs={6}>
                            <IconButton color="default" variant="contained" onClick={() => deleteExperimentHandler(id, name)}>
                              <ArchiveIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  );
                })
              : null}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100, 250]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          onChangePage={onPageChangeHandler}
          onChangeRowsPerPage={onRowsPerPageChangeHandler}
        />
      </Paper>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    display: "inline-block",
    maxWidth: "200px",
  },
  paper: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    // minWidth: 860,
  },
}));

const mapStateToProps = (state) => {
  return {
    user: state.system.user,
  };
};

export default connect(mapStateToProps, null)(withDialog(withSnackbar(ExperimentListPage)));
