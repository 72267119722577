import React, { Fragment, useEffect } from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import ImageMapper from "react-image-mapper";
import moment from "moment";
import envars from "../envars";

const FloorMapEditPanel = (props) => {
  const {
    width = 600,
    map = {
      url: envars.exampleFloorMapUrl,
      name: "expeirment-floormap",
      areas: [],
    },
    hoveredArea,
    setHoveredArea,
    onFloorMapImageClick,
    onFloorMapDeviceClick,
  } = props;

  const renderFloorMapHoverData = () => {
    if (!hoveredArea.data) return;
    // {hoveredArea.data ? <Typography color="textSecondary">R134A value: {hoveredArea.data}</Typography> : null}
    const data = hoveredArea.data;
    return (
      <Fragment>
        {data.timestamp ? <Typography color="textSecondary">Time: {data.timestamp}</Typography> : null}
        {data.status ? <Typography color="textSecondary">Status: {data.status}</Typography> : null}
        {data.r134a ? <Typography color="textSecondary">R134A(ppm): {data.r134a}</Typography> : null}
        {data.pres ? <Typography color="textSecondary">Last Pressure: {data.pres}</Typography> : null}
        {data.firstChangeTime ? <Typography color="textSecondary">First Change Time: {data.firstChangeTime}</Typography> : null}
      </Fragment>
    );
  };


  return (
    <Grid item xs={12}>
      <Paper className="paper-with-padding">
      <Grid container spacing={3} justify="space-between">
        <Grid item xs={12}>
          <Typography variant="h6" component="h2" color="primary">
           Floor Map
          </Typography>
        </Grid>
      </Grid>
        <Grid container spacing={1} justify="center" alignItems="center" alignContent="center">
          <Grid item>
            <ImageMapper
              src={map.url}
              map={map}
              width={width}
              onLoad={() => {}}
              onClick={onFloorMapDeviceClick}
              onMouseEnter={(area) => setHoveredArea(area)}
              onMouseLeave={(area) => setHoveredArea(null)}
              // onMouseMove={onFloorMapDeviceHover}
              onImageClick={onFloorMapImageClick}
              // onImageMouseMove={(evt) => this.moveOnImage(evt)}
            />
          </Grid>
        </Grid>
        <Grid container justify="flex-end">
          <Grid item>
            {hoveredArea ? (
              <Fragment>
                <Typography color="primary">Device {hoveredArea.deviceId} {hoveredArea.name}</Typography>
                {renderFloorMapHoverData()}
              </Fragment>
            ) : null}
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

// const mapStateToProps = (state) => {
//   return {
//     user: state.system.user,
//   };
// };

// export default connect(mapStateToProps, null)(withDialog(withSnackbar(FloorMapEditPanel)));
export default FloorMapEditPanel;
