import React, { Fragment } from "react";
import { Grid, Paper, Typography, FormControl, FormControlLabel, Checkbox, Button } from "@material-ui/core";
import moment from "moment";

import DateRangePicker from "../components/DateRangePicker";

const ExperimentValveController = (props) => {
  const { dosers, onChange, onSave } = props;

  return (
    <Grid item xs={12}>
      <Paper className="paper-with-padding">
        <Grid container spacing={3} justify="space-between">
          <Grid item xs={12}>
            <Typography variant="h6" component="h2" color="primary">
              Valve Control
            </Typography>
          </Grid>
        </Grid>
        {dosers.map((device, i) => (
          <Grid container spacing={2} justify="space-between" alignItems="flex-end" key={`doser-valve-row-${device.deviceId}`}>
            <Grid item xs={3}>
              <FormControlLabel
                key={`doser-device-valve-control-${device.deviceId}`}
                control={<Checkbox checked={true} disabled />}
                label={`${device.deviceId} Valve On/Off Time`}
              />
            </Grid>
            <Grid item>
              <FormControl component="fieldset">
                <DateRangePicker
                  from={device.valveStart || null}
                  to={device.valveEnd || null}
                  setFrom={(value) => {
                    onChange("valveStart")({ deviceId: device.deviceId, value });
                  }}
                  setTo={(value) => {
                    onChange("valveEnd")({ deviceId: device.deviceId, value });
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item>
              <Button variant="contained" color="secondary" onClick={() => onSave(device.deviceId)}>
                Update
              </Button>
            </Grid>
          </Grid>
        ))}
      </Paper>
    </Grid>
  );
};

export default ExperimentValveController;
