const validExperiment = (name, dosers, receivers, from, to) => {
  // console.log(receivers.length;
  if (!name) {
    return "Please enter the experiment name";
  }
  if (dosers.length <= 0) {
    return "Select at least ONE value control device.";
  }
  if (dosers.find((d) => d.presetValve && (!d.valveStart || !d.valveEnd))) {
    return "Please set the valve preset start time and endtime or uncheck the preset valve setting";
  }

  if (receivers.length <= 0) {
    return "Select at least ONE measurement device.";
  }

  if (receivers.find((d) => d.needAvgPressure && !d.avgPres)) {
    return "Please calculate the average pressure before experiment start";
  }

  if (!from) {
    return "Select the experiment start time";
  }
  if (!to) {
    return "Select the experiment end time";
  }

  if(from > to){
    return "The experiment start time can not be later than end time."
  }
};

export default validExperiment;
