import React, { Fragment } from "react";
import { Typography, Grid } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { DEVICE_STATUS } from "../utils/constants";

const DeviceStatusIcon = ({ status }) => {
  let statusColor;
  if (status === DEVICE_STATUS.ON) {
    statusColor = "#8bc34a";
  } else if (status === DEVICE_STATUS.WARM_UP) {
    statusColor = "orange";
  } else if (status === DEVICE_STATUS.OFF) {
    statusColor = "grey";
  } else {
    statusColor = "grey";
  }
  return (
    <Grid container> 
      <Grid item>
        <Typography variant="body1" style={{color: statusColor}}>
          {status || DEVICE_STATUS.OFF}
        </Typography>
      </Grid>
      <Grid item>
        <FiberManualRecordIcon htmlColor={statusColor} />
      </Grid>
    </Grid>
  );
};
export default DeviceStatusIcon;
