const convertPivotName = (data, devices) =>{
  let legendKeyDict = {};
  devices.forEach(d=>{
    legendKeyDict[`_${d.deviceId}`] = d.name;
  });
  let dataWithLegndKey = [];
  data.forEach(d => {
    let newData = {};
    newData.timestamp = d.timestamp;
    for(let key of Object.keys(legendKeyDict)){
      newData[legendKeyDict[key]] = d[key];
    }
    dataWithLegndKey.push(newData);
  })

  return dataWithLegndKey;
}

export default convertPivotName;