let envars;
if (process.env.REACT_APP_TG_ENV === "prd") {
  envars = {
    exampleFloorMapUrl: "https://storage.googleapis.com/tracer-gas/floormap/example-floormap.jpg",
    authServiceUrl: "https://tg-auth-service-tmvhkqyjwa-an.a.run.app",
    telemetryServiceUrl: "https://tg-telemetry-service-tmvhkqyjwa-an.a.run.app",
    // telemetryServiceUrl: "http://localhost:3004",
    mlServiceUrl: "https://tg-ml-service-tmvhkqyjwa-an.a.run.app",
    deviceServiceUrl: "https://tg-device-service-tmvhkqyjwa-an.a.run.app"
  }
} else if (process.env.REACT_APP_TG_ENV === 'dev') {
  envars = {
    exampleFloorMapUrl: "https://storage.googleapis.com/tracer-gas-dev/floormap/example-floormap.jpg",
    authServiceUrl: "https://tg-auth-service-dev-r5dbu5gxta-an.a.run.app",
    telemetryServiceUrl: "https://tg-telemetry-service-dev-r5dbu5gxta-an.a.run.app",
    deviceServiceUrl: "https://tg-device-service-dev-r5dbu5gxta-an.a.run.app",
    mlServiceUrl: "https://tg-ml-service-dev-r5dbu5gxta-an.a.run.app",
  };
} else {
  envars = {
    exampleFloorMapUrl: "https://storage.googleapis.com/tracer-gas-dev/floormap/example-floormap.jpg",
    authServiceUrl: "https://tg-auth-service-dev-r5dbu5gxta-an.a.run.app",
    telemetryServiceUrl: "https://tg-telemetry-service-dev-r5dbu5gxta-an.a.run.app",
    // telemetryServiceUrl: "http://localhost:3004",
    deviceServiceUrl: "https://tg-device-service-dev-r5dbu5gxta-an.a.run.app",
    mlServiceUrl: "https://tg-ml-service-dev-r5dbu5gxta-an.a.run.app",
  };
}

export default envars;
