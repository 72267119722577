import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Drawer, Grid, Paper, Typography, Button } from "@material-ui/core";

import { withSnackbar } from "../containers/SnackbarManager";
import { withDialog } from "../containers/DialogManager";

import DeviceInfoCard from "../components/DeviceInfoCard";
import DeviceSSIDCard from "../components/DeviceSSIDCard";

const DeviceConfigDrawer = (props) => {
  const { open, device, onBeforeClose, onSave } = props;
  const [changes, setChanges] = useState({});

  useEffect(() => {
    let changes = {
      name: device && device.name ? device.name : "",
      location: device && device.location ? device.location : "",
      ssid: device && device.ssid ? device.ssid : "",
      wifiPw: device && device.wifiPw ? device.wifiPw : "",
    };
    setChanges(changes);
  }, [device]);

  const onDeviceChange = (e) => {
    let updateChanges = { ...changes };
    updateChanges[e.target.name] = e.target.value;
    setChanges(updateChanges);
  };

  const onDeviceNameAndLocationSave = () => {
    let data = { name: changes.name, location: changes.location };
    onSave(device.deviceId, data);
  };

  const onDeviceSSIDSave = () => {
    let data = { ssidChange: 1, ssid: changes.ssid, wifiPw: changes.wifiPw };
    onSave(device.deviceId, data);
  };

  const onDrawerClose = () => {
    setChanges({});
    onBeforeClose();
  };

  if (!device) return <div />;
  return (
    <Drawer anchor="right" open={open} onClose={onDrawerClose}>
      <div className="drawer-on-right paper-with-padding">
        <Grid container spacing={2} direction="column">
          <Grid item>
            <Typography variant="h5" color="primary">
              Device {device.deviceId}
            </Typography>
            <Typography variant="body1" color="textSecondary">
              {device.type === "DOSER_TYPE" ? "Valve Contoller" : "R134A Measument Device"} ({device.type})
            </Typography>
          </Grid>
          <Grid item>
            <DeviceInfoCard
              name={changes.name || ""}
              location={changes.location || ""}
              onChange={onDeviceChange}
              onSave={onDeviceNameAndLocationSave}
            />
          </Grid>
          <Grid item>
            <DeviceSSIDCard
              ssid={changes.ssid || ""}
              wifiPw={changes.wifiPw || ""}
              onChange={onDeviceChange}
              onSave={onDeviceSSIDSave}
            />
          </Grid>
        </Grid>
      </div>
    </Drawer>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.system.user,
  };
};

export default connect(mapStateToProps, null)(withDialog(withSnackbar(DeviceConfigDrawer)));
