import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography, Button } from "@material-ui/core";
import moment from "moment";
import envars from "../envars";
import api, { handleApiFailureWithDialog } from "../utils/api";
import { DOSER_TYPE, RECEIVER_TYPE } from "../utils/constants";
import { withSnackbar } from "../containers/SnackbarManager";
import { withDialog } from "../containers/DialogManager";

import ExperimentCreatePannel from "../containers/ExperimentCreatePannel";
import PageLoadingView from "../components/PageLoadingView/PageLoadingView";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

const ExperimentCreatePage = (props) => {
  // const experimentId = props.match.params.id;
  const [loaded, setLoaed] = useState(true);

  const handleBackButton = async () => {
    // let apiResult = await api("delete", `${envars.telemetryServiceUrl}/experiments/${experimentId}`, null);
    // if (apiResult.data.success) {
    //   props.history.push('/experiments');
    // } else {
    //   handleApiFailureWithDialog(props.requestDialog, apiResult);
    // }
    props.history.push('/experiments');
  };

  const handleExperimentSave = async (data) => {
    setLoaed(false);
    let apiResult = await api("post", `${envars.telemetryServiceUrl}/experiments`, data);
    if (apiResult.data.success) {
      let experiment = apiResult.data.result.experiment;
      props.history.push(`/experiments/${experiment.id}`);
    } else {
      handleApiFailureWithDialog(props.requestDialog, apiResult);
    }
    setLoaed(true);
  };

  if (!loaded) return <PageLoadingView />;
  return (
    <div className="paper-with-padding">
      <Grid container spacing={2}>
        <Grid item xs="auto">
          <Typography variant="h4" component="h2">
            Create Experiment
          </Typography>
        </Grid>
        <Grid item xs="auto">
          <Button variant="contained" onClick={handleBackButton}>
            <ArrowBackIosIcon />
            Back
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {loaded ? (
            <ExperimentCreatePannel action="create" buttonText="Save" onExperimentSave={handleExperimentSave} />
          ) : (
            <PageLoadingView />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.system.user,
  };
};

export default connect(mapStateToProps, null)(withDialog(withSnackbar(ExperimentCreatePage)));
