import React from "react";
import { Grid } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

const DateRangePicker = (props) => {
  const { from, to, setFrom, setTo, disabled } = props;
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container spacing={4} alignItems="flex-start">
        <Grid item xs={12} sm={6} md={6}>
          <KeyboardDateTimePicker
            id="start-from"
            key="start-from"
            variant="inline"
            ampm={false}
            label="Start From"
            value={from}
            onChange={setFrom}
            // shouldDisableDate={dateDisabled}
            disabled={disabled}
            format="yyyy/MM/dd HH:mm"
            // margin="normal"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <KeyboardDateTimePicker
            id="end-to"
            key="end-to"
            variant="inline"
            ampm={false}
            label="End To"
            value={to}
            onChange={setTo}
            disabled={disabled}
            // shouldDisableDate={dateDisabled}
            format="yyyy/MM/dd HH:mm"
            // margin="normal"
            fullWidth
          />
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

export default DateRangePicker;
