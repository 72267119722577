import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography, Button } from "@material-ui/core";
import moment from "moment";
import envars from "../envars";
import { floorMapDefault, floorMapDefaultPrimary } from "../Themes";
import api, { handleApiFailureWithDialog } from "../utils/api";
import { DOSER_TYPE, RECEIVER_TYPE } from "../utils/constants";
import toHKTimeString from "../utils/to-hk-time-string";
import validExperiment from "../utils/experiment-validation";

import { withSnackbar } from "../containers/SnackbarManager";
import { withDialog } from "../containers/DialogManager";

import FloorMapEditPanel from "../containers/FloorMapEditPanel";
import ExperimentValveController from "../containers/ExperimentValveController";
import ExperimentSettingPanel from "../containers/ExperimentSettingPanel";
import ExperimentRealtimeDataChart from "../containers/ExperimentRealtimeDataChart";
import ExperimentRealtimeValveChart from "../containers/ExperimentRealtimeValveChart";
import ExperimentHistoryDataChart from "../containers/ExperimentHistoryDataChart";
import ExperimentHistoryValveChart from "../containers/ExperimentHistoryValveChart";
import ExperimentHistoryPressureChart from "../containers/ExperimentHistoryPressureChart";
import ExperimentRealtimePressureChart from "../containers/ExperimentRealtimePressureChart";

import ExportDataDownloadList from "../components/ExportDataDownloadList";
import PageLoadingView from "../components/PageLoadingView/PageLoadingView";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import SendIcon from "@material-ui/icons/Send";

const defaultMap = {
  url: envars.exampleFloorMapUrl,
  name: "expeirment-floormap",
  areas: [],
};

const ExperimentPage = (props) => {
  const experimentId = props.match.params.id;

  const [loaded, setLoaded] = useState(false);
  const [settingLoaded, setSettingLoaded] = useState(false);
  const [error, setError] = useState(null);

  const [isRealtime, setIsRealtime] = useState(false);
  const [experiment, setExperiment] = useState(null);
  const [bufferExperiment, setBufferExperiment] = useState(null);

  const [exportFileLoading, setExportFileLoading] = useState(false);
  const [exportFiles, setExportFiles] = useState(null);

  // const [bufferMap, setbufferMap] = useState(null);
  const defaultSelectDevice = { deviceId: null, name: null };
  const [map, setMap] = useState(defaultMap);
  const [selectDevice, setSelectDevice] = useState(defaultSelectDevice);
  const [hoveredArea, setHoveredArea] = useState(null);

  useEffect(() => {
    fetchExperimentData();
  }, []);

  useEffect(() => {
    if (experiment) setError(() => null);
    if (experiment && experiment.to) checkRealtimeModel();
  }, [experiment]);

  // useEffect(() => {
  //   handleFloorMapChange();
  // }, [bufferMap]);

  const checkRealtimeModel = () => {
    let current = moment().valueOf();
    if (current < experiment.to) {
      setIsRealtime(true);
    } else {
      setIsRealtime(false);
    }
  };

  const fetchExperimentData = async () => {
    let apiResult = await api("get", `${envars.telemetryServiceUrl}/experiments/${experimentId}`, null);
    if (apiResult.data.success) {
      let experiment = apiResult.data.result.experiment;
      if (!experiment.receivers || !experiment.dosers) {
        props.history.push(`/experiments`);
      }
      experiment.dosers.forEach((d) => {
        d.select = true;
        d.type = DOSER_TYPE;
      });
      experiment.receivers.forEach((d) => {
        d.select = true;
        d.type = RECEIVER_TYPE;
      });
      setBufferExperiment(experiment);
      setExperiment(experiment);
      if (experiment.map) {
        setMap(experiment.map);
      }
      setSelectDevice(defaultSelectDevice);
      setError(null);
      setSettingLoaded(true);
      setLoaded(true);
    } else {
      handleApiFailureWithDialog(props.requestDialog, apiResult);
    }
  };

  const handleExperimentSave = async () => {
    let selectDosers = bufferExperiment.dosers.map((d) => ({
      deviceId: d.deviceId,
      name: d.name,
      location: d.location,
    }));
    let selectReceivers = bufferExperiment.receivers.map((d) => ({
      deviceId: d.deviceId,
      name: d.name,
      location: d.location,
      avgPres: d.avgPres,
    }));
    let validationError = validExperiment(bufferExperiment.name, selectDosers, selectReceivers, bufferExperiment.from, bufferExperiment.to);
    // console.log(validationError);
    if (validationError) {
      setError(validationError);
      return false;
    }

    let filterMap = { ...map };
    filterMap.areas.forEach((area) => {
      area.preFillColor = area.type === DOSER_TYPE ? floorMapDefaultPrimary : floorMapDefault;
      delete area.data;
    });

    let payload = {
      action: "update-form",
      name: bufferExperiment.name,
      from: moment(bufferExperiment.from).valueOf(),
      to: moment(bufferExperiment.to).valueOf(),
      dosers: selectDosers,
      receivers: selectReceivers,
      map: filterMap,
    };
    // console.log(payload);
    setLoaded(false);
    let apiResult = await api("patch", `${envars.telemetryServiceUrl}/experiments/${experimentId}`, payload);
    if (apiResult.data.success) {
      fetchExperimentData();
    } else {
      setLoaded(true);
      handleApiFailureWithDialog(props.requestDialog, apiResult);
    }
  };

  const handleExperimentChange = (key) => (value) => {
    let updateExperiment = { ...bufferExperiment };
    updateExperiment[key] = value;
    setBufferExperiment(updateExperiment);
  };

  const handleExperimentReset = async () => {
    setSettingLoaded(false);
    fetchExperimentData();
  };

  const handleExperimentStop = async () => {
    let payload = {
      to: moment().valueOf(),
    };
    setLoaded(false);
    let apiResult = await api("patch", `${envars.telemetryServiceUrl}/experiments/${experimentId}`, payload);
    if (apiResult.data.success) {
      fetchExperimentData();
    } else {
      setLoaded(true);
      handleApiFailureWithDialog(props.requestDialog, apiResult);
    }
  };

  const handleExperimentDelete = async () => {
    props.requestDialog({
      title: "Warning",
      text: `Comfirm to Archive Experiment : ${experiment.name} ? `,
      buttons: [
        {
          text: "cancle",
        },
        {
          text: "ok",
          onClick: async () => {
            let apiResult = await api("delete", `${envars.telemetryServiceUrl}/experiments/${experimentId}`);
            if (apiResult.data.success) {
              props.history.push("/experiments");
            } else {
              handleApiFailureWithDialog(props.requestDialog, apiResult);
            }
          },
        },
      ],
    });
  };

  const handleBackButton = () => {
    props.history.push("/experiments");
  };

  const handleValveChange = (props) => (e) => {
    let newDosers = [...bufferExperiment.dosers];
    let idx = newDosers.findIndex((d) => d.deviceId === e.deviceId);
    if (idx > -1) {
      newDosers[idx][props] = e.value;
      setBufferExperiment({ ...bufferExperiment, dosers: newDosers });
    }
  };

  const fetchDevice = async (deviceId) => {
    let apiResult = await api("get", `${envars.deviceServiceUrl}/devices/${deviceId}`, null);
    if (apiResult.data.success) {
      let device = apiResult.data.result.device;
      let { valveStart, valveEnd } = device;
      if (valveStart || valveEnd) {
        return { showDialog: true, valveStart, valveEnd };
      } else {
        return { showDialog: false };
      }
    } else {
      handleApiFailureWithDialog(props.requestDialog, apiResult);
    }
  };

  const valveSaveApi = async (data) => {
    setLoaded(false);
    let apiResult = await api("patch", `${envars.telemetryServiceUrl}/experiments/${experimentId}/valve`, data);
    if (apiResult.data.success) {
      fetchExperimentData();
    } else {
      setLoaded(true);
      handleApiFailureWithDialog(props.requestDialog, apiResult);
    }
  };

  const handleValveSave = async (deviceId) => {
    let { showDialog, valveStart, valveEnd } = await fetchDevice(deviceId);
    let device = bufferExperiment.dosers.find((d) => d.deviceId === deviceId);
    if (!device) {
      return false;
    }

    let data = {
      deviceId: device.deviceId,
      presetValve: 1,
      valveStart: device.valveStart ? moment(device.valveStart).valueOf() : null,
      valveEnd: device.valveEnd ? moment(device.valveEnd).valueOf() : null,
    };
    // console.log(data);
    if (showDialog) {
      props.requestDialog({
        title: "Warning",
        text: `Comfirm to update valve start/end time? You will override the last time setting (${toHKTimeString(valveStart)} - ${toHKTimeString(valveEnd)}) `,
        buttons: [
          {
            text: "cancle",
          },
          {
            text: "ok",
            onClick: () => {
              valveSaveApi(data);
            },
          },
        ],
      });
    } else {
      valveSaveApi(data);
    }
  };

  /**
   * Floor Map Control
   */
  // const handleFloorMapChangeBuffer = (deviceFloorMapAreas) => {
  //   setbufferMap(deviceFloorMapAreas);
  // };

  const handleFloorMapChange = (deviceFloorMapAreas) => {
    let updateMap = { ...map };
    if (!updateMap.areas) return;
    updateMap.areas.forEach((area) => {
      let bufferArea = deviceFloorMapAreas ? deviceFloorMapAreas.find((d) => d.deviceId === area.deviceId) : null;
      if (bufferArea) {
        area.preFillColor = bufferArea.preFillColor ? bufferArea.preFillColor : area.preFillColor;
        area.data = bufferArea.data;
        area.name = bufferArea.name;
      }
    });
    // console.log(updateMap);
    setMap(updateMap);
  };

  const onFloorMapImageClick = (e) => {
    const w = 26;
    const h = 16;
    const x = e.nativeEvent.layerX;
    const y = e.nativeEvent.layerY;
    // let coords = [x, y, 15];
    let coords = [x - w, y - h, x + w, y + h];
    if (selectDevice && selectDevice.deviceId) {
      let updateMap = { ...map };
      let idx = updateMap.areas.findIndex((area) => area.deviceId === selectDevice.deviceId);
      let color = selectDevice.type === DOSER_TYPE ? floorMapDefaultPrimary : floorMapDefault;
      if (idx >= 0) {
        updateMap.areas[idx].shape = "rect";
        updateMap.areas[idx].coords = coords;
        updateMap.areas[idx].preFillColor = color;
      } else {
        // updateMap.areas.push({ name: selectDevice.name, deviceId: selectDevice.deviceId, shape: "circle", coords, preFillColor: "grey" });
        updateMap.areas.push({
          type: selectDevice.type,
          deviceId: selectDevice.deviceId,
          shape: "rect",
          coords,
          preFillColor: color,
        });
      }
      // console.log(updateMap);
      setMap(updateMap);
    }
  };

  const handleSelectFloorMapDevice = (device) => (e) => {
    if (selectDevice && device.deviceId === selectDevice.deviceId) {
      setSelectDevice({ deviceId: null, name: null });
    } else {
      setSelectDevice(device);
    }
  };

  const handleRemoveFloorMapDevice = (device) => (e) => {
    let updateMap = { ...map };
    let idx = updateMap.areas.findIndex((area) => area.deviceId === device.deviceId);
    if (idx > -1) {
      updateMap.areas.splice(idx, 1);
    }
    console.log(updateMap.areas);
    setMap(updateMap);
  };

  const updateCalibration = async () => {
    setLoaded(false);
    const receiverIds = experiment.receivers.map((r) => r.deviceId);
    let newCalbrationApiResult = await api("post", `${envars.mlServiceUrl}/r134a`, {
      deviceIds: receiverIds.join(","),
      from: experiment.from,
      to: experiment.to,
    });
    setLoaded(true);
    // if (newCalbrationApiResult && newCalbrationApiResult.data && newCalbrationApiResult.data.ok) {
    //   setLoaded(true);
    // }
  };

  /**
   * Export Data
   */
  const onExportButtonHandler = async () => {
    if (moment() < moment(experiment.to)) {
      props.requestDialog({
        title: "Warning",
        text: `Export Data before the experiment end time ${toHKTimeString(experiment.to)}? `,
        buttons: [
          {
            text: "ok",
            onClick: async () => {
              await exportData();
            },
          },
          {
            text: "cancel",
          },
        ],
      });
    } else {
      await exportData();
    }
  };

  const exportData = async () => {
    setExportFileLoading(true);
    // let allDevices = experiment.receivers.map((r) => r.deviceId);
    // let exportDataResponse = await Promise.all(allDevices.map((deviceId) => exportDataApi(deviceId)));
    let exportDataResponse = await Promise.all([exportDataApi()]);
    if (exportDataResponse.length > 0) {
      let readFileError = false;
      for (let response of exportDataResponse) {
        if (!response.filename) {
          const reader = new FileReader();
          reader.readAsText(response || null);
          reader.onload = () => {
            let errorResult = {};
            errorResult.data = JSON.parse(reader.result);
            handleApiFailureWithDialog(props.requestDialog, errorResult);
          };
          readFileError = true;
          break;
        }
      }
      if (readFileError) {
        setExportFiles(null);
        setExportFileLoading(false);
      } else {
        setExportFiles(exportDataResponse);
        setExportFileLoading(false);
      }
    }
  };

  const exportDataApi = async () => {
    let querys = [];
    let receiverIds = experiment.receivers.map((r) => r.deviceId);
    let doserIds = experiment.dosers.map((r) => r.deviceId);
    querys.push(`receivers=${receiverIds}`);
    querys.push(`dosers=${doserIds}`);
    querys.push(`name=${experiment.name}`);
    querys.push(`from=${moment(experiment.from).valueOf()}`);
    querys.push(`to=${moment(experiment.to).valueOf()}`);

    const options = {
      responseType: "blob",
    };

    let exportDataApiResult = await api("get", `${envars.telemetryServiceUrl}/r134a/csv?${querys.join("&")}`, null, options);
    if (exportDataApiResult.data && exportDataApiResult.data.type === "text/csv") {
      const disposition = exportDataApiResult.headers["content-disposition"];
      if (disposition && disposition.match(/attachment/)) {
        let filename = disposition.replace(/attachment;.*filename=/, "").replace(/"/g, "");
        const file = new Blob([exportDataApiResult.data]);
        const tempUrl = window.URL.createObjectURL(file);
        return { filename, tempUrl };
      }
    } else {
      return exportDataApiResult.data;
    }
  };

  /**
   * Render
   */
  if (!experimentId) {
    props.history.push(`/experiments`);
  }
  if (!loaded) return <PageLoadingView />;

  return (
    <div className="paper-with-padding">
      <Grid container spacing={2}>
        <Grid item xs="auto">
          <Typography variant="h4" component="h2">
            {experiment.name}
          </Typography>
        </Grid>
        <Grid item xs="auto">
          <Button variant="contained" onClick={handleBackButton}>
            <ArrowBackIosIcon />
            Back
          </Button>
        </Grid>
        <Grid item xs={"auto"}>
          <Button fullWidth variant="contained" color="primary" onClick={onExportButtonHandler}>
            <SendIcon fontSize="small" /> Export Data
          </Button>
        </Grid>
        {/* <Grid item xs={"auto"}>
          <Button fullWidth variant="contained" color="primary" onClick={updateCalibration}>
            <SendIcon fontSize="small" /> Update Calibration
          </Button>
        </Grid> */}
      </Grid>
      {exportFileLoading || (exportFiles && exportFiles.length > 0) ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ExportDataDownloadList exportFiles={exportFiles} exportFileLoading={exportFileLoading} />
          </Grid>
        </Grid>
      ) : null}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {isRealtime ? (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <ExperimentRealtimeDataChart
                  from={experiment.from}
                  to={experiment.to}
                  receivers={experiment.receivers}
                  dosers={experiment.dosers}
                  isRealtime={isRealtime}
                  setIsRealtime={setIsRealtime}
                  onFloorMapChange={handleFloorMapChange}
                />
              </Grid>
              <Grid item xs={12}>
                <ExperimentRealtimePressureChart from={experiment.from} to={experiment.to} receivers={experiment.receivers} dosers={experiment.dosers} isRealtime={isRealtime} />
              </Grid>
              <Grid item xs={12}>
                <ExperimentRealtimeValveChart
                  from={experiment.from}
                  to={experiment.to}
                  dosers={experiment.dosers}
                  avgPres={experiment.receivers[0].avgPres}
                  isRealtime={isRealtime}
                  onFloorMapChange={handleFloorMapChange}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <ExperimentHistoryDataChart
                  from={experiment.from}
                  to={experiment.to}
                  receivers={experiment.receivers}
                  dosers={experiment.dosers}
                  isRealtime={isRealtime}
                  onFloorMapChange={handleFloorMapChange}
                />
              </Grid>
              <Grid item xs={12}>
                <ExperimentHistoryPressureChart from={experiment.from} to={experiment.to} dosers={experiment.dosers} receivers={experiment.receivers} isRealtime={isRealtime} />
              </Grid>
              <Grid item xs={12}>
                <ExperimentHistoryValveChart
                  from={experiment.from}
                  to={experiment.to}
                  dosers={experiment.dosers}
                  isRealtime={isRealtime}
                  avgPres={experiment.receivers[0].avgPres}
                  onFloorMapChange={handleFloorMapChange}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {settingLoaded ? (
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <ExperimentValveController dosers={experiment.dosers} onChange={handleValveChange} onSave={handleValveSave} />
              <FloorMapEditPanel width={600} map={map} hoveredArea={hoveredArea} setHoveredArea={setHoveredArea} onFloorMapImageClick={onFloorMapImageClick} onFloorMapDeviceClick={(area) => {}} />
              <ExperimentSettingPanel
                isRealtime={isRealtime}
                experimentId={experimentId}
                error={error}
                name={bufferExperiment.name}
                from={bufferExperiment.from}
                to={bufferExperiment.to}
                dosers={bufferExperiment.dosers}
                receivers={bufferExperiment.receivers}
                selectDevice={selectDevice}
                onChange={handleExperimentChange}
                onFloorMapEdit={handleSelectFloorMapDevice}
                onFloorMapRemove={handleRemoveFloorMapDevice}
                onExperimentSave={handleExperimentSave}
                onExperimentReset={handleExperimentReset}
                onExperimentStop={handleExperimentStop}
                onExperimentDelete={handleExperimentDelete}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <PageLoadingView />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.system.user,
  };
};

export default connect(mapStateToProps, null)(withDialog(withSnackbar(ExperimentPage)));
