import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography, Button, FormControl, Select, MenuItem } from "@material-ui/core";
import moment from "moment";
import envars from "../envars";
import api, { handleApiFailureWithDialog } from "../utils/api";
import { DOSER_TYPE, RECEIVER_TYPE } from "../utils/constants";
// import useInterval from "../utils/use-interval";
import { withSnackbar } from "./SnackbarManager";
import { withDialog } from "./DialogManager";

import PageLoadingView from "../components/PageLoadingView/PageLoadingView";
import CustomLineChart from "../components/CustomLineChart";
import { TrafficOutlined } from "@material-ui/icons";
import { primaryMain, secondaryMain, chartTheme, floorMapDefault } from "../Themes";

import convertPivotName from '../utils/convert-pivot-name';

// const STROKES_COLOR = ["#5B2C6F", "#633974", "#76448A", "#884EA0", "#9B59B6", "#AF7AC5", "#C39BD3", "#D7BDE2"];
const SELECT_COLOR = "#5B2C6F";
const UNSELECT_COLOR = "#EBDEF0";

const ExperimentHistoryPressureChart = (props) => {
  const { from, to, receivers, dosers, isRealtime } = props;

  const avgPres = receivers[0].avgPres;
  const devices = receivers.concat(dosers);
  const defaultStrokes = devices.map((d, i) => chartTheme.primary[i % chartTheme.primary.length]);
  const dataKeys = devices.map((r) => `_${r.deviceId}`);
  const legendKeys = devices.map((r) => r.name);

  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState([]);

  const [storkes, setStorkes] = useState(defaultStrokes);
  const [selectDevice, setSelectDevice] = useState(null);
  const [refAreaLeft, setRefAreaLeft] = useState("");
  const [refAreaRight, setRefAreaRight] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    let reciverIds = receivers.map((r) => r.deviceId);
    let doserIds = dosers.map(d=>d.deviceId);
    let query = `receivers=${reciverIds}&dosers=${doserIds}&from=${moment(from).valueOf()}&to=${moment(to).valueOf()}&avgPres=${avgPres}`;
    let apiResult = await api("get", `${envars.telemetryServiceUrl}/pressure?${query}`);
    if (apiResult.data.success) {
      let pressure = apiResult.data.result.pressure;
      pressure.sort((a, b) => {
        return moment(a.timestamp).valueOf() - moment(b.timestamp).valueOf();
      });
      // console.log(pressure);
      // init empty data time
      let emptyDataTime = {};
      for (let key of dataKeys) {
        emptyDataTime[key] = [];
      }

      for (let i = 0; i < pressure.length; i++) {
        pressure[i].timestamp = moment(pressure[i].timestamp).valueOf();
        for (let key of dataKeys) {
          if (!pressure[i][key]) {
            emptyDataTime[key].push(i);
          } else {
            const currentValue = +parseFloat(pressure[i][key]).toFixed(2);
            pressure[i][key] = currentValue;
            let emptyDataIdx = emptyDataTime[key];
            if (emptyDataIdx.length > 0) {
              let lastNonEmptyIdx = emptyDataIdx[0] === 0 ? 0 : emptyDataIdx[0] - 1;
              let lastPressureData = pressure[lastNonEmptyIdx][key];
              let lastEmptyIdx = emptyDataIdx[emptyDataIdx.length - 1];
              if (i === lastEmptyIdx + 1) {
                let avgValue = (lastPressureData + currentValue) / 2;
                for (let j of emptyDataIdx) {
                  pressure[j][key] = avgValue;
                }
                emptyDataTime[key] = [];
              }
            }
          }
        }
      }

      let dataWithLegndKey = convertPivotName(pressure, devices);
      setData(dataWithLegndKey);
      if (!loaded) setLoaded(true);
    } else {
      handleApiFailureWithDialog(props.requestDialog, apiResult);
    }
  };

  const resetLineChartClick = (e) => {
    setStorkes(defaultStrokes);
    setSelectDevice(null);
  };

  const selectLine = (e) => {
    let value = e.dataKey || e.target.value;
    let selectedDevice = devices.find((d) => d.name === value);
    let selectedLineIdx = legendKeys.findIndex((k) => k === value);
    let newStorkes = [...storkes];
    newStorkes = newStorkes.map((stroke, i) => (i === selectedLineIdx ? SELECT_COLOR : UNSELECT_COLOR));
    setStorkes(newStorkes);
    setSelectDevice(selectedDevice);
  };


  const renderChart = () => {
    if (!loaded) {
      return (
        <Grid container justify="center" alignItems="center">
          <PageLoadingView />
        </Grid>
      );
    } else {
      return (
        <Fragment>
          <Grid item xs={12}>
            <CustomLineChart
              key="pressure-history"
              data={data}
              type="monotone"
              Xtype="number"
              yAxisLabel={{ value: "Pressure", angle: -90, position: "insideLeft" }}
              yDomain={["auto", "dataMax+25"]}
              dataKeys={legendKeys}
              strokes={storkes}
              storkeWidth={1.5}
              activeDot={{ r: 3, onClick: selectLine }}
              dot={false}
              style={{ width: "100%", height: 380, margin: { top: 0, right: 40, left: 0, bottom: 16 } }}
              isRealtime={isRealtime}
              showBrush={true}
              brushStroke={SELECT_COLOR}
              devices={devices}
            />
          </Grid>
        </Fragment>
      );
    }
  };

  return (
    <Paper className="paper-with-padding">
      <Grid container spacing={3} justify="space-between">
        <Grid item xs={12}>
          <Typography variant="h6" component="h2" color="primary">
            Tracer Gas Pressure - History
          </Typography>
        </Grid>
      </Grid>
      {renderChart()}
      <Grid container spacing={1} justify="flex-end">
        <Grid item>
          <Button fullWidth variant="contained" onClick={resetLineChartClick}>
            Reset Selected Line
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.system.user,
  };
};

export default connect(mapStateToProps, null)(withDialog(withSnackbar(ExperimentHistoryPressureChart)));
