import React, { Fragment } from "react";
import moment from "moment";
import {
  Grid,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  TextField,
  Checkbox,
  IconButton,
  Button,
  Tooltip,
  makeStyles,
} from "@material-ui/core";
import { DOSER_TYPE, RECEIVER_TYPE } from "../utils/constants";
import { secondaryMain } from "../Themes";
import DateRangePicker from "./DateRangePicker";
import RoomIcon from "@material-ui/icons/Room";
import ExploreOffIcon from "@material-ui/icons/ExploreOff";
// import SendIcon from "@material-ui/icons/Send";

const ExperimentDeviceEditor = (props) => {
  const {
    selectDevice,
    dosers,
    receivers,
    onDoserChange,
    onReceiverChange,
    canEditSelectedDevice,
    onFloorMapEdit,
    onFloorMapRemove,
    showValvePreset,
    showFloorMapRemove = false,
  } = props;

  const classes = useStyles();

  const renderFloorMapEditButton = (deviceId, name, type) => {
    let color;
    if (selectDevice && selectDevice.deviceId === deviceId) {
      color = secondaryMain;
    }
    return (
      <Grid container spacing={1} justify="center">
        <Grid item>
          <Tooltip title="Set Device on Floor Map">
            <Button variant="contained" color="primary" onClick={onFloorMapEdit({ deviceId, name, type })}>
              <RoomIcon size="medium" style={{ color }} />
            </Button>
          </Tooltip>
        </Grid>
        {showFloorMapRemove && (
          <Grid item>
            <Tooltip title="Remove Device on Floor Map" onClick={onFloorMapRemove({ deviceId, name, type })}>
              <Button variant="contained">
                <ExploreOffIcon size="medium" />
              </Button>
            </Tooltip>
          </Grid>
        )}
      </Grid>
    );
  };

  const renderDoserDeviceEditor = () => {
    const type = DOSER_TYPE;
    return dosers.map((device, i) => (
      <Grid item xs={12} md={12} key={`${type}-row-${i}`}>
        <Grid container spacing={1} justify="flex-start">
          <Grid item xs={3} sm={2} md={1}>
            <FormControlLabel
              key={`${type}-device-control-${i}`}
              control={<Checkbox checked={device.select} onChange={onDoserChange("select")} name={i.toString()} />}
              label={device.deviceId}
              disabled={!canEditSelectedDevice}
            />
          </Grid>
          <Grid item xs={4} sm={3} md={2}>
            <TextField
              id={`${type}-pres-${i}`}
              label="Avg Pressure"
              name={i.toString()}
              value={device.avgPres || "--"}
              fullWidth
              size="small"
              disabled
            />
          </Grid>
          <Grid item xs={5} sm={6} md={2}>
            <TextField
              id={`${type}-name-${i}`}
              label="name"
              name={i.toString()}
              value={device.name}
              onChange={onDoserChange("name")}
              fullWidth
              size="small"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6} sm={9} md={5}>
            <TextField
              id={`${type}-location-${i}`}
              label="Location"
              name={i.toString()}
              value={device.location}
              onChange={onDoserChange("location")}
              fullWidth
              size="small"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6} sm={3} md={2}>
            {renderFloorMapEditButton(device.deviceId, device.name, device.type)}
          </Grid>
        </Grid>
        {device.select && showValvePreset ? (
          <Grid container spacing={1} justify="flex-end" alignItems="flex-end" key={`${type}-valve-row-${i}`}>
            <Grid item xs={3} sm={4}>
              <FormControlLabel
                key={`${type}-device-valve-control-${i}`}
                control={<Checkbox checked={device.presetValve} onChange={onDoserChange("presetValve")} name={i.toString()} />}
                label={"Valve Start/End Time"}
              />
            </Grid>
            <Grid item sm={6}>
              <FormControl component="fieldset">
                <DateRangePicker
                  from={device.valveStart || null}
                  to={device.valveEnd || null}
                  setFrom={(value) => {
                    onDoserChange("valveStart")({ target: { name: i.toString(), value } });
                  }}
                  setTo={(value) => {
                    onDoserChange("valveEnd")({ target: { name: i.toString(), value } });
                  }}
                  disabled={!device.presetValve}
                />
              </FormControl>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    ));
  };

  const renderReceiverDeviceEditor = () => {
    const type = RECEIVER_TYPE;
    return receivers.map((device, i) => (
      <Grid item xs={12} key={`${type}-row-${i}`}>
        <Grid container spacing={2} justify="flex-start">
          <Grid item xs={3} sm={2} md={1}>
            <FormControlLabel
              key={`${type}-device-control-${i}`}
              control={<Checkbox checked={device.select} onChange={onReceiverChange("select")} name={i.toString()} />}
              label={device.deviceId}
              disabled={!canEditSelectedDevice}
            />
          </Grid>
          <Grid item xs={4} sm={3} md={2}>
            <TextField
              id={`${type}-pres-${i}`}
              label="Avg Pressure"
              name={i.toString()}
              value={device.avgPres || "--"}
              fullWidth
              size="small"
              disabled
            />
          </Grid>
          <Grid item xs={5} sm={6} md={2}>
            <TextField
              id={`${type}-name-${i}`}
              label="name"
              name={i.toString()}
              value={device.name}
              onChange={onReceiverChange("name")}
              fullWidth
              size="small"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6} sm={9} md={5}>
            <TextField
              id={`${type}-location-${i}`}
              label="Location"
              name={i.toString()}
              value={device.location}
              onChange={onReceiverChange("location")}
              fullWidth
              size="small"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6} sm={3} md={2}>
            {renderFloorMapEditButton(device.deviceId, device.name, device.type)}
          </Grid>
        </Grid>
        {/* {device.select ? <Grid container></Grid> : null} */}
      </Grid>
    ));
  };

  return (
    <Fragment>
      <Grid item xs={12}>
        <Grid container spacing={2} justify="space-around" alignItems="center">
          <Grid item xs={12}>
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend" className={classes.label}>
                Valve Device
              </FormLabel>
              <FormGroup>
                <Grid container spacing={2}>
                  {renderDoserDeviceEditor()}
                </Grid>
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} justify="space-around" alignItems="center">
          <Grid item xs={12}>
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend" className={classes.label}>
                R134A Measurement Device
              </FormLabel>
              <FormGroup>
                <Grid container spacing={2}>
                  {renderReceiverDeviceEditor()}
                </Grid>
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  label: {
    padding: "16px 0px 16px 0px",
  },
  helperText: {
    fontSize: "1rem",
  },
}));

export default ExperimentDeviceEditor;
