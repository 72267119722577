import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Typography,
  TablePagination,
  makeStyles,
  TableContainer,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
// import queryString from "query-string";

import envars from "../envars";
import api, { handleApiFailureWithDialog } from "../utils/api";
import { DOSER_TYPE, RECEIVER_TYPE } from "../utils/constants";
import { secondaryMain } from "../Themes";

import PageLoadingView from "../components/PageLoadingView/PageLoadingView";
import DeviceConfigDrawer from "../containers/DeviceConfigDrawer";

import { withSnackbar } from "../containers/SnackbarManager";
import { withDialog } from "../containers/DialogManager";
import toHKTimeString from "../utils/to-hk-time-string";

const DeviceListPage = (props) => {
  const classes = useStyles();
  const isSuperAdmin = props.user.role === "super-admin";
  const [loaded, setLoaded] = useState(false);
  // const [error, setError] = useState(null);
  const [devices, setDevices] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [editing, setEditing] = useState(false);
  const [editingDevice, setEditingDevice] = useState(null);

  useEffect(() => {
    if (!loaded) fetchData();
  }, [loaded]);

  const fetchData = async () => {
    setLoaded(false);
    let apiResult = await api("get", `${envars.deviceServiceUrl}/devices?page=${page}&size=${rowsPerPage}`, null);
    if (apiResult.data.success) {
      let devices = apiResult.data.result.devices;
      setDevices(devices);
      setCount(apiResult.data.result.count);
      setLoaded(true);
    } else {
      handleApiFailureWithDialog(props.requestDialog, apiResult);
    }
  };

  const onPageChangeHandler = (e, pageStartsFromZero) => {
    setPage(pageStartsFromZero + 1);
    setLoaded(false);
  };

  const onRowsPerPageChangeHandler = (e) => {
    setRowsPerPage(e.target.value);
    setLoaded(false);
  };

  const onEditButtonHandler = (device) => {
    setEditingDevice(device);
    setEditing(true);
  };

  const onBeforeEditDeviceClose = () => {
    setEditing(false);
    setEditingDevice(null);
  };

  const onEditDeviceSave = async (deviceId, change) => {
    let apiResult = await api("post", `${envars.deviceServiceUrl}/devices/${deviceId}`, change);
    if (apiResult.data.success) {
      setLoaded(false);
      onBeforeEditDeviceClose();
    } else {
      handleApiFailureWithDialog(props.requestDialog, apiResult);
    }
  };

  // const addDeviceHandler = async () => {
  //   let apiResult = await api("post", `${envars.telemetryServiceUrl}/experiments`, { ownerCommunity: props.user.community });
  //   if (apiResult.data.success) {
  //     let experiment = apiResult.data.result.experiment;
  //     props.history.push(`/experiments/create/${experiment.id}`);
  //   } else {
  //     handleApiFailureWithDialog(props.requestDialog, apiResult);
  //   }
  //   // props.history.push(`/experiments/${id}`);
  // };

  if (!loaded) return <PageLoadingView />;

  return (
    <div className="paper-with-padding">
      <Typography variant="h4" component="h2" className={classes.title}>
        Devices
      </Typography>
      {/* <Button onClick={addDeviceHandler} color="primary" variant="contained" style={{ marginLeft: 8, marginBottom: 12 }}>
        <AddIcon />
        Add New
      </Button> */}
      <Paper className={classes.paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Default SSID</TableCell>
              <TableCell>Wifi SSID</TableCell>
              <TableCell>Wifi Password</TableCell>
              <TableCell>Avg Pressure</TableCell>
              <TableCell>Last Ingestion Time</TableCell>
              {isSuperAdmin ? <TableCell>Owner</TableCell> : null}
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {devices && devices.length > 0
              ? devices.map((device) => {
                  const { deviceId, type, name, location, lastIngestionTime, ownerCommunity, ssid, wifiPw, avgPres, defaultSSID } = device;
                  return (
                    <TableRow key={deviceId}>
                      <TableCell>{deviceId}</TableCell>
                      <TableCell>{type}</TableCell>
                      <TableCell>{name}</TableCell>
                      <TableCell>{location}</TableCell>
                      <TableCell style={{ color: `${defaultSSID === "Y" ? secondaryMain : "textPrimary"}` }}>
                        {defaultSSID ? (defaultSSID === "Y" ? "Yes" : "No") : ""}
                      </TableCell>
                      <TableCell>{ssid}</TableCell>
                      <TableCell>{wifiPw}</TableCell>
                      <TableCell align="center">{avgPres}</TableCell>
                      <TableCell>{lastIngestionTime ? toHKTimeString(lastIngestionTime) : null}</TableCell>
                      {isSuperAdmin ? <TableCell> {ownerCommunity}</TableCell> : null}
                      <TableCell>
                        <Button color="primary" variant="contained" onClick={() => onEditButtonHandler(device)}>
                          <EditIcon /> Edit
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })
              : null}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100, 250]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          onChangePage={onPageChangeHandler}
          onChangeRowsPerPage={onRowsPerPageChangeHandler}
        />
      </Paper>
      <DeviceConfigDrawer open={editing} device={editingDevice} onBeforeClose={onBeforeEditDeviceClose} onSave={onEditDeviceSave} />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    display: "inline-block",
    maxWidth: "200px",
  },
  paper: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    minWidth: 860,
  },
}));

const mapStateToProps = (state) => {
  return {
    user: state.system.user,
  };
};

export default connect(mapStateToProps, null)(withDialog(withSnackbar(DeviceListPage)));
