export const TRACER_GAS_WORKSPACE_VERSION = "2.4.1";

export const IAQ_TYPE = "IAQ";
export const EM_TYPE = "EM";
export const DOSER_TYPE = "doser";
export const RECEIVER_TYPE = "receiver";

export const DEVICE_STATUS = {
  ON: "ON",
  WARM_UP: "Warm Up",
  OFF: "OFF",
};
