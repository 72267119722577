import moment from "moment";
import { DEVICE_STATUS } from "./constants";

const getDeivceStatus = (device) => {
  if (!device.lastIngestionTime) return DEVICE_STATUS.OFF;
  const currentTs = moment().valueOf();
  let delta = Math.abs(moment(device.lastIngestionTime) - moment(currentTs));
  if (device.warmUpEnd && device.warmUpStart && device.lastIngestionTime <= device.warmUpEnd && device.lastIngestionTime >= device.warmUpStart){
    if(delta / (30 * 1000) >= 1){
      return DEVICE_STATUS.OFF;
    }else{
      return DEVICE_STATUS.WARM_UP;
    }
  }
  if (delta / (30 * 1000) <= 1) {
    return DEVICE_STATUS.ON;
  } else {
    return DEVICE_STATUS.OFF;
  }

};

export default getDeivceStatus;
