import { createMuiTheme } from "@material-ui/core/styles";
import { indigo, amber, pink } from "@material-ui/core/colors";

export const primaryMain = "#684664";
export const secondaryMain = "#F6B656";
export const floorMapDefault = "#E0E0E0";
export const floorMapDefaultPrimary = "#5c5c5c";
export const floorMapDefaultStroke = "#263238";
export const chartTheme = {
  primary: ["#76d7c4", "#c39bd3", "#d98880", "#f0b27a", "#7fb3d5", "#73C6B6", "#c0392b", "#9b59b6", "#2980b9", "#f4d03f", "#16a085", "#f39c12"],
  seconday: ["#F6B656", "#f1c40f", "#ec7063", "#f8c471", "#99a3a4", "#52be80", "#dc7633"],
};

export const themeStyle = createMuiTheme({
  palette: {
    primary: {
      main: primaryMain,
    },
    secondary: {
      // BE5869, fab52e, 403A3E
      main: secondaryMain,
    },
    error: pink,
  },
  gradientContained: `linear-gradient(45deg, ${primaryMain} 40%, ${secondaryMain} 100%)`,
  overrides: {
    MuiButton: {
      containedSecondary: {
        background: `linear-gradient(45deg, ${primaryMain} 40%, ${secondaryMain} 100%)`,
        color: "white",
      },
    },
  },
});
